import React, { useState, Fragment } from 'react'
import { connect } from "react-redux"
import * as ACTIONS from "../../redux/actions"
import * as ROUTES from '../../misc/routes'

import {
  FormControl,
  Box,
  IconButton,
  Avatar,
  Grid,
  makeStyles,
  Typography,
  Link,
} from '@material-ui/core'

import RefreshIcon from '@material-ui/icons/Refresh'

import Page from "../App/Page"
import Panel from "../Panel/Panel"
import Username from '../App/Username'
import AreYouSureDialog from './AreYouSureDialog'
import PPInput from '../App/PPInput'
import { FileCopy } from '@material-ui/icons'
import CopyToClipboard from 'react-copy-to-clipboard'
import DeleteAccountWithPassword from './DeleteAccountWithPassword'

const useStyles = makeStyles(theme => ({
  root: {
    padding: "2rem",
    display: "flex",

  },
  avatarContainer: {
    marginBottom: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  avatar: {
    width: "5rem",
    height: "5rem",
    backgroundColor: theme.palette.primary.main
  },
  keyContainer: {
    position: 'relative',
  },
  refreshIconContainer: {
    position: "absolute",
    top: "2.75rem",
    left: "calc(100% + 1.5rem)",

    "& button": {
      color: `${theme.palette.text.primary}`
    }
  },
  smaller: {
    fontSize: ".75rem",
    paddingTop: ".25rem",
    paddingBottom: ".25rem",
  },
  agent: {
    textDecoration: "underline",
    cursor: "pointer",
  }
}))

const Profile = (props) => {
    
    const classes = useStyles()
    
    const [regenerateKeyDialogOpen, setRegenerateKeyDialogOpen] = useState(false)
    const [deleteAccountDialogOpen, setDeleteAccountDialogOpen] = useState(false)

    const { state } = props
    const user = state.user
    const email = user.email
    const photoURL = user.photoURL
    const key = state.documentKey
    const providerId = state.user.providerData[0].providerId
    const isPasswordNecessary = providerId === "password" //XXX: hardcoded value, would be better to get the value directelly from new FIREBASE.emailAuthProvider() 
    
    console.debug("The user's provider ID: ", providerId)

    const handleCloseClick = () => {
      props.redirect(ROUTES.HOME)
    }

    const handleRegenerateKeyClick = () => {
      setRegenerateKeyDialogOpen(true)
    }

    const handleDeleteAccountClick = () => {
      setDeleteAccountDialogOpen(true)
    }

    // REGENERATE KEY
    const handleRegenerateDialogAccept = () => {
      setRegenerateKeyDialogOpen(false)
      props.generateDocumentKey()
    }

    const handleRegenerateDialogDecline = () => {
      setRegenerateKeyDialogOpen(false)
    }

    const handleDeleteAccountDialogAccept = (password) => {
      setDeleteAccountDialogOpen(false)
      props.deleteUser(password)
    }
    
    const handleDeleteAccountDialogDecline = () => {
      setDeleteAccountDialogOpen(false)
    }

    const handlePasswordResetClick = () => {
      props.sendPasswordResetEmail(email)
    }

    const handleAgentClick = () => {
      props.redirect(ROUTES.APPLE_APP_STORE)
    }

    return (
      <Fragment>

        <AreYouSureDialog
          open={regenerateKeyDialogOpen}
          onAccept={handleRegenerateDialogAccept}
          onDecline={handleRegenerateDialogDecline}
          title="Regenerate key?">
          Do you really want to regenerate your key? You cannot undo this action! After your code has been regenerated, your agent will not work until you set up the new key!
        </AreYouSureDialog>

        <DeleteAccountWithPassword
          open={deleteAccountDialogOpen}
          isPasswordNecessary={isPasswordNecessary}
          onAccept={handleDeleteAccountDialogAccept}
          onDecline={handleDeleteAccountDialogDecline}
          title="Delete your account?"
          messageLines={["Do you really want to delete your account?","Be careful, you cannot undo this action!"]} />
        
        <Page>
          <Panel
          title="Profile"
          onClose={handleCloseClick}>
            <Grid container justifyContent="flex-end" className={classes.root}>
              
              <Grid item xs={12} sm={6} md={4} className={classes.avatarContainer}>
                <Avatar alt={"Name of the user"} src={photoURL} className={classes.avatar}></Avatar>
                <Typography variant="h6">
                  <Username />
                </Typography>
                <Link onClick={handlePasswordResetClick} className={classes.smaller}>Reset your password</Link>
                <Link onClick={handleDeleteAccountClick} className={classes.smaller}>Delete your account</Link>
              </Grid>
              
              <Grid item xs={12} sm={6} md={8} container>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <label htmlFor="label-for-email">Email</label>
                    <PPInput
                      readOnly
                      id="label-for-email"
                      label="Email"
                      value={email} />
                  </FormControl>
                </Grid>

                <Grid item xs={12} className={classes.keyContainer}>

                  <div className={classes.refreshIconContainer}>
                    <IconButton onClick={handleRegenerateKeyClick} size="small">
                      <RefreshIcon fontSize="inherit"/>
                    </IconButton>
                  </div>
                  
                  <FormControl fullWidth>
                    <label htmlFor="label-for-key">Key</label>
                    <PPInput
                      readOnly
                      startIcon={<CopyToClipboard text={key}><FileCopy /></CopyToClipboard>}
                      className={classes.input}
                      id="label-for-key"
                      value={key} />
                  </FormControl>
                </Grid>
              
              </Grid>
              
              <Grid item xs={12} sm={6} md={8}>
                <Box mt={2}>
                  This key identifies you. Keep it a secret! Use the same key here and in your <span onClick={handleAgentClick} className={classes.agent}>Agent</span>.
                </Box>
              </Grid>

            </Grid>
          </Panel>
        </Page>
      </Fragment>
    )
}

const mapStateToProps = (state) => ({
    state: state
  })
  
  const mapDispatchToProps = dispatch => {
    return {
      deleteUser: (password) => dispatch(ACTIONS.deleteUser(password)),
      generateDocumentKey: () => dispatch(ACTIONS.generateDocumentKey()),
      sendPasswordResetEmail: (email) => dispatch(ACTIONS.sendPasswordResetEmail(email)),
      redirect: (to) => dispatch(ACTIONS.redirect(to)),
    }
  }
  
export default connect(mapStateToProps, mapDispatchToProps)(Profile)