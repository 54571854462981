import * as ACTION_TYPES from "./actionTypes"

import * as C from '../misc/common'

const initialState = {

  //DRAG & DROP
  dragInfo: false,
  dragStatus: C.DRAG_STATUS_NO_DRAG,
  
  // UPLOAD
  uploadProgress: 0,
  operationStatus: C.OPERATION_IDLE,
  documentKey: null,

  // AUTH
  user: null,
  signInInProgress: false,

  // FILE
  file: null,
  remoteFileInfo: {
    status: null,
    contentType: null,
    modified: null,
    size: null,
  },

  // FLASH
  flash: null,

};

const reducer = function(state = initialState, action) {
  
    switch (action.type) {

        case ACTION_TYPES.SET_DRAG_INFO: return {...state, dragInfo: action.payload}
        case ACTION_TYPES.SET_DRAG_STATUS: return {...state, dragStatus: action.payload}

        case ACTION_TYPES.SET_OPERATION_STATUS: return {...state, operationStatus: action.payload}
        
        case ACTION_TYPES.SET_FILE: return {...state, file: action.payload}
        case ACTION_TYPES.SET_UPLOAD_PROGRESS: return {...state, uploadProgress: action.payload}
       
        case ACTION_TYPES.SET_DOCUMENT_KEY: return {...state, documentKey: action.payload}
        case ACTION_TYPES.SET_REMOTE_FILE_INFO: return {...state, remoteFileInfo: action.payload}

        case ACTION_TYPES.AUTH_STATE_CHANGE: return {...state, user: action.payload}
        case ACTION_TYPES.SIGN_IN: return {...state}
        case ACTION_TYPES.SIGN_IN_SUCCESS: return {...state}
        case ACTION_TYPES.SIGN_IN_WITH_GOOGLE: return {...state}
        case ACTION_TYPES.SIGN_OUT: return {...state, folderKey: null}
        case ACTION_TYPES.SET_SIGN_IN_PROGRESS_FLAG: return {...state, signInInProgress: action.payload}

        case ACTION_TYPES.FLASH: return {...state, flash: action.payload}
        case ACTION_TYPES.CLEAR_FLASH: return {...state, flash: null}

        default: return state
    }
}

export default reducer