
import React from 'react'

import {
    //
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core'

const CIRCLE_RADIUS = 2.5
const COVER_CIRCLE_FACTOR = .8
const WIDTH = 0.75
const SHADOW_SIZE = 0.1
const BOX_SHADOW = `inset -0.05rem -0.05rem 0.2rem ${SHADOW_SIZE}rem rgba(255, 255, 255, 0.75), inset 0.05rem 0.05rem 0.2rem ${SHADOW_SIZE}rem rgba(166, 200, 200, 0.75)`

const useStyles = makeStyles(theme => ({
    topContainer: {
        marginTop: "1rem",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    center: {
        position: "relative",
        zIndex: 0,
        minWidth: `${WIDTH}rem`,
        minHeight: `${WIDTH}rem`,
        marginTop: "1rem",
        marginBottom: "1rem",
    },
    shadowedCircle: {
        position: "absolute",
        width: `${CIRCLE_RADIUS}rem`,
        height: `${CIRCLE_RADIUS}rem`,
        top: `calc((50% - (${CIRCLE_RADIUS}rem / 2)))`,
        left: `calc((50% - (${CIRCLE_RADIUS}rem / 2)))`,
        boxShadow: BOX_SHADOW,
        borderRadius: "50%",
        backgroundColor: theme.palette.background.paper,
        zIndex: -1,
    },
    rectangle: {
        position: "absolute",
        width: "100%",
        height: "100%",
        boxShadow: BOX_SHADOW,
        backgroundColor: theme.palette.background.paper,
        borderRadius: `${WIDTH / 2}rem`,
    },
    coverCircle: {
        position: "absolute",
        width: `${CIRCLE_RADIUS * COVER_CIRCLE_FACTOR}rem`,
        height: `${CIRCLE_RADIUS * COVER_CIRCLE_FACTOR}rem`,
        top: `calc((50% - (${CIRCLE_RADIUS * COVER_CIRCLE_FACTOR}rem / 2)))`,
        left: `calc((50% - (${CIRCLE_RADIUS * COVER_CIRCLE_FACTOR}rem / 2)))`,
        borderRadius: "50%",
        backgroundColor: theme.palette.background.paper,
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "70%",
    },
    left: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
    },
    right: {
        marginTop: "1.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    bottom: {
        display: "flex",
        justifyContent: "center",
    },
    spacer: {
        minWidth: "14rem",
        width: "100%",
        maxWidth: "50%",
    },

    [theme.breakpoints.down('md')]: {
        spacer: {
            maxWidth: "50%"
        }
    },

    [theme.breakpoints.up('md')]: {
        spacer: {
            maxWidth: "90%"
        }
    },

    // vertical
    [theme.breakpoints.up('md')]: {
        topContainer: {
            flexDirection: "row",
        },
        center: {
            marginTop: 0,
            marginBottom: 0,
            marginLeft: "1rem",
            marginRight: "1rem",
        },
        left: {
            width: "100%"
        },
        right: {
            width: "100%",
        },
        bottom: {
            justifyContent: "flex-end",
        }
    },

}))

const Separator = () => {

    const classes = useStyles()
    return (
        <div className={classes.center}>
            <div className={classes.shadowedCircle}></div>
            <div className={classes.rectangle}></div>
            <div className={classes.coverCircle}>OR</div>
        </div>
    )
}

const DualPanelContainer = (props) => {

    const classes = useStyles()

    return (
        <div>
            <div className={classes.topContainer}>
                <div className={classes.left}>
                    <div className={classes.spacer}>
                        {props.left}
                    </div>
                </div>
                <Separator />
                <div className={classes.right}>
                    <div className={classes.spacer}>
                        {props.right}
                    </div>
                </div>
            </div>
            <div className={classes.bottom}>
                {props.bottom}
            </div>
        </div>
    )
}

export default DualPanelContainer