// DRAG
export const SET_DRAG_INFO = "SET_DRAG_INFO"
export const SET_DRAG_STATUS = "SET_DRAG_STATUS"


// OPERATION STATUS
export const SET_OPERATION_STATUS = "SET_OPERATION_STATUS"


// FILE
export const FILE_DROP = "FILE_DROP"
export const SET_FILE = "SET_FILE"
export const DELETE_FILE = "DELETE_FILE"
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS"

export const SET_DOCUMENT_KEY = "SET_DOCUMENT_KEY"
export const GENERATE_DOCUMENT_KEY = "GENERATE_DOCUMENT_KEY"
export const SET_REMOTE_FILE_INFO = "SET_REMOTE_FILE_INFO"

// AUTH & USER
export const AUTH_STATE_CHANGE = "AUTH_STATE_CHANGE"
export const SIGN_IN = "SIGN_IN"
export const SIGN_IN_WITH_GOOGLE = "SIGN_IN_WITH_GOOGLE"
export const SIGN_IN_WITH_FACEBOOK = "SIGN_IN_WITH_FACEBOOK"
export const SIGN_IN_WITH_APPLE = "SIGN_IN_WITH_APPLE"
export const SIGN_OUT = "SIGN_OUT"
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS"
export const SET_SIGN_IN_PROGRESS_FLAG = "SET_SIGN_IN_PROGRESS_FLAG"
export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const RELOAD_USER = "RELOAD_USER"
export const SEND_EMAIL_VERIFICATION = "SEND_EMAIL_VERIFICATION"
export const SEND_EMAIL_VERIFICATION_SUCCESS = "SEND_EMAIL_VERIFICATION_SUCCESS"
export const SEND_PASSWORD_RESET_EMAIL = "END_PASSWORD_RESET_EMAIL"
export const DELETE_USER = "DELETE_USER"

// LISTENER CONTROL
export const START_DOCUMENT_DATA_LISTENER = "START_DOCUMENT_DATA_LISTENER"
export const STOP_DOCUMENT_DATA_LISTENER = "STOP_DOCUMENT_DATA_LISTENER"
export const START_USER_DATA_LISTENER = "START_USER_DATA_LISTENER"
export const STOP_USER_DATA_LISTENER = "STOP_USER_DATA_LISTENER"

// APPLICATION CALLBACKS
export const APPLICATION_DID_LAUNCH = "APPLICATION_DID_LAUNCH"
export const APPLICATION_DID_FINISH = "APPLICATION_DID_FINISH"

// REDIRECT
export const REDIRECT = "REDIRECT"

// FLASH
export const FLASH = "FLASH"
export const CLEAR_FLASH = "CLEAR_FLASH"
