import React, {Fragment} from 'react'
import { connect } from "react-redux"

import CustomLinearProgress from './CustomLinearProgress'
import MimeTypeIcon from './MimeTypeIcon'
import Working from './Working'

const prettyBytes = require('pretty-bytes')

const FrontendWorking = (props) => {

    const uploadProgress = props.state.uploadProgress
    const file = props.state.file
    const uploadedDataSize = (file.size / 100) * uploadProgress

    return (
        <Working
            top={<Fragment><MimeTypeIcon type={file.type} />{file.name}</Fragment>}
            center={<CustomLinearProgress variant="determinate" value={uploadProgress} />}
            bottomLeft={`${prettyBytes(uploadedDataSize)} of ${prettyBytes(file.size)}`}
            bottomRight={`Uploaded: ${uploadProgress}%`}
        />
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        //
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FrontendWorking)