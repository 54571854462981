import React from 'react'
import * as COM from "../../misc/common"

import { 
    makeStyles,
} from '@material-ui/core'

const PPButton = (props) => {

    const useStyles = makeStyles(theme => ({
        root: {
            padding: ".5rem",
            borderRadius: "1rem",
            textTransform: "uppercase",
            boxShadow: "-.75rem -.75rem 1.25rem rgba(255, 255, 255, 0.8), .63rem .63rem 1.25rem rgba(166, 180, 200, 0.7)",
    
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
    
            "&:hover": {
                boxShadow: "none"
            },
            "&:active": {
                boxShadow: ".38rem .38rem .63rem rgba(166, 180, 200, 0.81)"
            }
        },
        default: {
            background: "linear-gradient(90deg, #56B9A7 0%, #58A6B7 100%)",
            color: theme.printPrincess.colors.white,
            border: "none",
        },
        simple: {
            background: theme.printPrincess.colors.inputBackground,
            border: `1px solid ${theme.printPrincess.colors.text}`,
        },
        margins: {
            margin: "1rem"
        },
        fullWidth: {
            width: "100%",
        },
        withControl: {
            minWidth: props.minWidth || 0
        }
    }))
    const classes = useStyles()
    const classBuilder = new COM.ClassBuilder(classes.root)

    if (props.fullWidth) classBuilder.add(classes.fullWidth)
    if (props.addMargins) classBuilder.add(classes.margins)
    if (props.simple) { classBuilder.add(classes.simple) } else { classBuilder.add(classes.default) }

    return (
        <button
            type="button"
            onClick={props.onClick}
            className={classBuilder.asString()}>
            <span className={classes.withControl}>{props.children}</span>
        </button>
    )
}

export default PPButton