import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from "react-redux";
import * as ROUTES from '../../misc/routes'

import Upload from '../Upload/Upload'
import SignUp from '../Sign/SignUp'
import SignIn from '../Sign/SignIn'
import ForgotPassword from '../ForgotPassword'
import NotVerified from '../NotVerified'
import PrivateRoute from '../PrivateRoute'
import Profile from '../Profile/Profile'
import Disclaimer from '../Pages/Disclaimer'
import FlashDialog from '../Flash/FlashDialog'
import DevelopmentInProgress from '../Pages/DevelopmentInProgress'
import Footer from './Footer'
import Header from './Header'


import { 
  makeStyles,
} from '@material-ui/core/'

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      fontFamily: "'Montserrat'",
      fontSize: "16px",
    },
    body: {
      position: "relative",
      margin: 0,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.printPrincess.colors.numbered[6],
      "& > div": {
        flex: 1,
        width: "100%",
        color: theme.printPrincess.colors.white,
        display: "flex",
        flexDirection: "column",
      }
    },
    main: {
      minWidth: "320px",
      flex: 1,
      backgroundColor: theme.printPrincess.colors.numbered[3],
    },
    label: {
      padding: "0.5rem 0 0.5rem 1rem",
      color: theme.printPrincess.colors.numbered[4],
    },
    a: {
      cursor: "pointer",
      textDecoration: "none",
      fontWeight: "bold",
    },
    rootContainer: {
      // empty
    }
  },
}))

const App = (props) => {
   
  const classes = useStyles()
  
  return (

  <div className={classes.rootContainer}>

    <FlashDialog /> 

    <Header />

    <main> 

      <Switch>
        <Route exact path={ROUTES.HOME}>
          <Redirect to={ROUTES.UPLOAD} />
        </Route>

        <Route path={ROUTES.SIGN_IN}>
          <SignIn />
        </Route>

        <Route path={ROUTES.SIGN_UP}>
          <SignUp />
        </Route>

        <Route path={ROUTES.DISCLAIMER}>
          <Disclaimer />
        </Route>

        <Route path={ROUTES.DEVELOPMENT_IN_PROGRESS}>
          <DevelopmentInProgress />
        </Route>

        <Route path={ROUTES.NOT_VERIFIED}>
          <NotVerified />
        </Route>

        <Route path={ROUTES.FORGOT_PASSWORD}>
          <ForgotPassword />
        </Route>       

        <PrivateRoute path={ROUTES.UPLOAD}>
          <Upload />
        </PrivateRoute>  

        <PrivateRoute path={ROUTES.PROFILE}>
          <Profile />
        </PrivateRoute>

      </Switch>

    </main>

    <Footer />

  </div>

  )
}

const mapStateToProps = (state) => ({
  state: state
})

const mapDispatchToProps = dispatch => {
  return {
    //
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)