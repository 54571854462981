import React from 'react'

import SvgIcon from '@material-ui/core/SvgIcon'

const AppIcon = (props) => {

    return (
        <SvgIcon {...props} viewBox="0 0 995 995">
        <g id="print-princess-logo" transform="translate(0.000000, 0.160688)" fillRule="nonzero">
            <g id="face" transform="translate(281.000000, 529.839678)">
                <path d="M259,0 C221.536267,0 191,30.9853514 191,69.0000477 L245.754686,69.0000477 C245.754686,61.6332946 251.740016,55.6161755 259,55.6161755 C266.259984,55.6161755 272.245314,61.6332946 272.245314,69.0000477 L327,69.0000477 C327,30.9853514 296.463733,0 259,0" id="rightEye"></path>
                <path d="M67.94458,55.6161755 C75.259984,55.6161755 81.189894,61.6332946 81.189894,69.0000477 L136,69.0000477 C136,30.9853514 105.463733,0 67.94458,0 C30.480848,0 0,30.9853514 0,69.0000477 L54.754686,69.0000477 C54.754686,61.6332946 60.684597,55.6161755 67.94458,55.6161755" id="leftEye"></path>
                <path d="M160.5,191.951961 C138.107661,191.951961 119.872316,173.565413 119.872316,151.000104 L65,151.000104 C65,203.986792 107.900174,247.000171 160.5,247.000171 C213.155252,247.000171 256,203.986792 256,151.000104 L201.183111,151.000104 C201.183111,173.565413 182.947766,191.951961 160.5,191.951961" id="mouth"></path>
            </g>
            <path d="M442.807074,0 L533.362442,124.412195 L640.522653,89.6566367 L657.671917,84.0304106 L655.675705,94.1031702 L638.254232,182.761605 L629.758688,225.753057 C747.498873,290.295597 828.017428,414.288946 830.918914,557.337638 L831,557.339697 L831,712.839805 L888,712.839805 L888,827.839884 C888,920.071501 813.231553,994.84 721,994.84 C643.357082,994.84 578.089761,941.853734 559.385122,870.068312 C526.397491,892.638943 486.490373,905.839938 443.5,905.839938 C400.877705,905.839938 361.286096,892.864026 328.460047,870.64708 C309.56187,942.136104 244.435758,994.84 167,994.84 C75.6907623,994.84 1.49711525,921.559395 0.0223732287,830.601537 L0,827.839884 L0,713.839494 L57,713.839806 L57,557.339697 L57.0810858,557.337638 C59.9380964,416.481697 138.050874,294.10139 252.853281,228.76288 L226.671917,83.7581736 L352.342443,124.412195 L442.807074,0 Z M443.694724,395.512565 C427.974073,410.750921 407.562712,420.068924 385.147425,420.265455 L384.42366,420.268627 L216.131669,420.268627 L216.131669,625.350087 C216.131669,761.957127 314.825538,873.266798 436.837612,875.479331 L440.542008,875.512897 L446.784385,875.512897 C569.322267,875.512897 669.117916,765.569296 671.101575,629.481864 L671.131669,625.350087 L671.131669,420.268627 L502.83968,420.268627 C480.266231,420.268627 459.584413,410.914775 443.694724,395.512565 Z M444.354417,102.777861 L389.237938,178.577231 L312.671917,153.808363 L330.58339,252.99441 L555.748304,252.99441 L563.432547,214.127191 L574.046753,160.111155 L575.262965,153.974226 L564.814605,157.402061 L499.526178,178.577231 L444.354417,102.777861 Z" id="head"></path>
        </g>
        </SvgIcon>
    )
}

export default AppIcon
