import React from 'react'

import { 
    Typography,
    makeStyles
} from '@material-ui/core'

import TextPage from "../App/TextPage"
import MailTo from "../App/MailTo"

const LAST_UPDATE = "07-12-2021"
const COMPANY_DETAILS = "StarCat.dev Ltd., HU26707532"
const URL = "http://www.printprincess.net"
const EFFECTIVA_AS_OF = "07-12-2021"

const useStyles = makeStyles(theme => ({
    root: {
        "& p": {
            color: theme.printPrincess.colors.numbered[4]
        },
        "& h5": {
            color: theme.printPrincess.colors.numbered[2]
        },
        "& h6": {
            color: theme.printPrincess.colors.numbered[2]
        }
    },
}))

const Disclaimer = (props) => {

    const classes = useStyles()
    
    return (
        <TextPage title="Disclaimer">
            <div className={classes.root}>
                <Typography variant="h5">Privacy Policy</Typography>
                <p>
                    Last updated: {LAST_UPDATE}
                </p>
                <p>
                    {COMPANY_DETAILS}, ("us", "we", or "our") operates {URL} (the "Site"). 
                    This page informs you of our policies regarding the collection, use and disclosure of Personal Information 
                    we receive from users of the Site.
                </p>
                <p>
                    We use your Personal Information only for providing and improving the Site. By using the Site, you agree to 
                    the collection and use of information in accordance with this policy.
                </p>
                <Typography variant="h6">Information Collection And Use</Typography>
                <p>
                    While using our Site, we may ask you to provide us with certain personally identifiable information
                    that can be used to contact or identify you. Personally identifiable information may include, but is not
                    limited to your name ("Personal Information").
                </p>
                <Typography variant="h6">Log Data</Typography>
                <p>
                    Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data").
                </p>
                <p>
                    This Log Data may include information such as your computer's Internet Protocol ("IP") address,
                    browser type, browser version, the pages of our Site that you visit, the time and date of your visit,
                    the time spent on those pages and other statistics.
                </p>
                <p>
                    In addition, we may use third party services such as Google Analytics that collect, monitor and analyze this.
                </p>
                <Typography variant="h6">Communications</Typography>
                <p>
                    We may use your Personal Information to contact you with newsletters, marketing or promotional
                    materials and other information.
                </p>
                <Typography variant="h6">Cookies</Typography>
                <p>
                    Cookies are files with small amount of data, which may include an anonymous unique identifier.
                    Cookies are sent to your browser from a web site and stored on your computer's hard drive.
                </p>
                <p>
                    Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all
                    cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may
                    not be able to use some portions of our Site.
                </p>
                <Typography variant="h6">Security</Typography>
                <p>
                    The security of your Personal Information is important to us, but remember that no method of
                    transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to
                    use commercially acceptable means to protect your Personal Information, we cannot guarantee its
                    absolute security.
                </p>
                <Typography variant="h6">Changes To This Privacy Policy</Typography>
                <p>
                    This Privacy Policy is effective as of {EFFECTIVA_AS_OF} and will remain in effect except with respect to any
                    changes in its provisions in the future, which will be in effect immediately after being posted on this
                    page.
                </p>
                <p>
                    We reserve the right to update or change our Privacy Policy at any time and you should check this
                    Privacy Policy periodically. Your continued use of the Service after we post any modifications to the
                    Privacy Policy on this page will constitute your acknowledgment of the modifications and your
                    consent to abide and be bound by the modified Privacy Policy.
                </p>
                <p>
                    If we make any material changes to this Privacy Policy, we will notify you either through the email
                    address you have provided us, or by placing a prominent notice on our website.
                </p>
                <Typography variant="h6">Contact Us</Typography>
                <p>
                    If you have any questions about this Privacy Policy, <MailTo>please contact us.</MailTo>
                </p>
            </div>
        </TextPage>
    )
}

export default Disclaimer

