import React, {useState } from 'react'

import {
    makeStyles,
  } from '@material-ui/core'

import AreYouSureDialog from './AreYouSureDialog'
import PPInput from '../App/PPInput'

const useStyles = makeStyles(theme => ({
    passwordContainer: {
        margin: "1rem 0 1rem 0",
        "& > div": {
            paddingBottom: "1rem"
        }
    },
}))

const DeleteAccountWithPassword = (props) => {

    const classes = useStyles()
    const { messageLines, open, onDecline, onAccept, title, isPasswordNecessary } = props
    const [password, setPassword] = useState("")

    const handleChange = (ev) => {
        setPassword(ev.target.value)
    }

    const handleOnAccept = (ev) => {
        onAccept(password)
    }

    return (
        <AreYouSureDialog
            open={open}
            title={title}
            onDecline={onDecline}
            onAccept={handleOnAccept}>

            {messageLines.map( (message, index) => <div key={index}><span>{message}</span><br /></div> )}
            {isPasswordNecessary
                ? 
                <div className={classes.passwordContainer}>
                    <div>For your safety, please type your password!</div>
                    <PPInput onChange={handleChange} type="password" placeholder="Your password" />
                </div>
                : null 
            }

        </AreYouSureDialog>
    )
}
 
export default DeleteAccountWithPassword
