import React from 'react'

import { connect } from "react-redux"
import * as ACTIONS from "../../redux/actions"

import {
    makeStyles,
 } from '@material-ui/core/'

import PPButton from '../App/PPButton'
import { ClassBuilder } from '../../misc/common'

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 1000,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    container: {
        width: "95%",
        maxWidth: "40rem",
        padding: "2.5rem",
        borderRadius: "2.5rem",
        backgroundColor: theme.printPrincess.colors.white,
        color: theme.printPrincess.colors.text,
        boxShadow: "0 0 1.5rem rgba(0, 0, 0, 0.3), .63rem .63rem 1.5rem rgba(102, 133, 105, 0.7)",
    },
    title: {
        fontSize: "2rem",
        fontWeight: 300,
        textAlign: "center",
    },
    body: {
        padding: "2rem",
        "& ul": {
            margin: 0,
            paddingLeft: "1.5rem",
        },
    },
    actions: {
        padding: "1rem",
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
    },
    general: {
        color: theme.printPrincess.colors.numbered[2],
    },
    error: {
        color: theme.printPrincess.colors.red
    },
    warning: {
        color: theme.printPrincess.colors.red
    },
    success: {
        color: theme.printPrincess.colors.numbered[2]
    }
}))

const PrintMessages = ({messages}) => {

    if (!messages || messages.length < 1) return null 
    return (
        <ul>
            {messages.map( (item, index) => <li key={index}>{item}</li>)}
        </ul>    
    )
}

const FlashDialog = (props) => {

    const classes = useStyles()
    const { state, clearFlash } = props
    const { flash } = state

    if (!flash) return null

    const { messages } = flash
    const classBuilder = new ClassBuilder(classes.title, classes[flash.severity])

    console.debug("Flash object: ", flash)
    console.debug(`Flash dialog has been opened with severity: ${flash.type}, title: ${flash.title}, messages: ${flash.messages} as object: `, flash.messages, `and payload: ${flash.payload}`)

    return (

        <div className={classes.root}>
            <div className={classes.container}>
                <div className={classBuilder.asString()}>{flash.title}</div>
                <div className={classes.body}>
                    <PrintMessages messages={messages} />
                </div>
                <div className={classes.actions}>
                    <PPButton simple minWidth="8rem" onClick={clearFlash} color="secondary" autoFocus>Ok</PPButton>
                </div>
            </div>
        </div>
        
    )
}

const mapStateToProps = (state) => ({
    state: state
  })
  
  const mapDispatchToProps = dispatch => {
    return {
      clearFlash: () => dispatch(ACTIONS.clearFlash())
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(FlashDialog)