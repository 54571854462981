import React from 'react'
import { connect } from "react-redux";
import * as ACTIONS from "../../redux/actions"
import * as ROUTES from '../../misc/routes'

import {
    makeStyles,
} from '@material-ui/core/'

import AppIcon from '../Icons/AppIcon'

const AppIconWithText = (props) => {

    const useStyles = makeStyles((theme) => ({
      root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
        color: theme.printPrincess.colors.numbered[props.numberedColor || 5],
        "& span": {
          filter: "drop-shadow(2px 2px 2px rgba(255, 255, 255, 0.28))",
          textShadow: "inset 0px 4px 3px rgba(0, 0, 0, 0.15)",
          paddingLeft: "0.5rem",
          fontSize: "1.5rem",
          fontWeight: "800",
          display: props.compact ? "none" : "inline"
        },
        "& img": {
          width: "2.5rem",
          height: "2.5rem",
        },
        "& svg": {
          filter: "drop-shadow(2px 2px 2px rgba(255, 255, 255, 0.28))",        
        }
      },
      [theme.breakpoints.up('sm')]: {
        root: {
          "& span": {
            display: "inline"
          }
        }
      }
    }))
    const classes = useStyles()

    const handleClick = (ev) => {
      props.redirect(ROUTES.HOME)
    }
      
    return (
      <div className={classes.root} onClick={handleClick}>
        <AppIcon />
        <span>PrintPrincess</span>
      </div>
    )
}

const mapStateToProps = (state) => ({
  state: state
})

const mapDispatchToProps = dispatch => {
  return {
    redirect: (to) => dispatch(ACTIONS.redirect(to)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppIconWithText)