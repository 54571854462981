import React, {useMemo } from 'react'
import {useDropzone} from 'react-dropzone'
import UploadToCloud from '../Svg/UploadToCloud'
import * as COM from '../../misc/common'

import { 
    useTheme,
} from '@material-ui/core'

const StyledDropzone = (props) => {

    const theme = useTheme()
    const sdStyle = {
        baseStyle: {
            padding: "2rem",
            width: "100%",
            flex: 1,
            minHeight: "10rem",
            height: "100%",
            borderRadius: "0.5rem",
            background: theme.printPrincess.colors.inputBackground,
            boxShadow: theme.printPrincess.shadows.input,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
        },
        activeStyle: {
            background: "repeating-linear-gradient(45deg, #D6E9E2, #D6E9E2 2rem, #add5d0 2rem, #add5d0 4rem)",
            color: theme.printPrincess.colors.numbered[2],
        },
        acceptStyle: {
            background: "repeating-linear-gradient(45deg, #D6E9E2, #D6E9E2 2rem, #add5d0 2rem, #add5d0 4rem)",
            color: theme.printPrincess.colors.numbered[2],
        },
        rejectStyle: {
            background: "repeating-linear-gradient(45deg, #D6E9E2, #D6E9E2 2rem, #d7c0c5 2rem, #d7c0c5 4rem)",
            color: theme.printPrincess.colors.red,
            fontWeight: "bold",
        },
        icon: {
            width: "3rem",
            height: "3rem",
            marginBottom: "0.5rem",
            fill: theme.palette.secondary.main,
            cursor: "pointer"
        },
        bold: {
            fontWeight: "bold"
        }
    }

    const handleDrop = (acceptedFiles, rejectedFiles) => {

        console.debug("DROP with accepted files: ", acceptedFiles," rejected files: ", rejectedFiles)
       
        if (!acceptedFiles || acceptedFiles.length < 1) { 
            props.onError(rejectedFiles[0].errors)
            return
        }

        props.onDrop(acceptedFiles[0])
    }

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: COM.SUPPORTED_MIME_TYPES,
        multiple: false,
        maxSize: parseInt(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE),
        onDrop: handleDrop,
    });

    const style = useMemo(() => ({
        ...sdStyle.baseStyle,
        ...(isDragActive ? sdStyle.activeStyle : {}),
        ...(isDragAccept ? sdStyle.acceptStyle : {}),
        ...(isDragReject ? sdStyle.rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept,
        sdStyle.activeStyle,
        sdStyle.acceptStyle,
        sdStyle.rejectStyle,
        sdStyle.baseStyle,
    ])

    const iconNecessary = !isDragReject && !isDragAccept
    let dndMessage = <p>Drag 'n' drop some files here, or <span style={sdStyle.bold}>click</span> to select files</p>

    //NOTE: Special case for Safari. Safari doesn't allow to get the file info before droped.
    if (isDragActive && !isDragReject && !isDragAccept) {
        dndMessage = "Please drop the file to check & upload!"
    }

    if (isDragReject) {
        dndMessage = "File error! :("
    }
    
    if (isDragAccept) {
        dndMessage = "File is accepted! :)"
    }



    return (
        <div {...getRootProps({style})}>
            <input {...getInputProps()} />
            <>
                {iconNecessary ? <UploadToCloud style={{...sdStyle.icon}} /> : null}
                <span>{dndMessage}</span>
            </>
        </div>
    )
}

export default StyledDropzone