import React from 'react';

const EmailCircleIcon = (props) => {

    return (
        <svg {...props} viewBox="0 0 24 24">
            <g id="email" fillRule="nonzero">
                <path d="M12,2 C17.514,2 22,6.486 22,12 C22,17.514 17.514,22 12,22 C6.486,22 2,17.514 2,12 C2,6.486 6.486,2 12,2 Z M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 Z M12,12.55 L6.008,7.98 L17.991,7.98 L12,12.55 Z M12,13.838 L6,9.209 L6,15.98 L18,15.98 L18,9.209 L12,13.838 L12,13.838 Z"></path>
            </g>
        </svg>
    )
}

export default EmailCircleIcon
