import React from 'react'
import { connect } from "react-redux"

import {
    makeStyles,
    Grid,
} from '@material-ui/core/'

import Panel from './Panel/Panel'
import Page from './App/Page'

import * as ACTIONS from "../redux/actions"
import PPButton from './App/PPButton'

const useStyles = makeStyles(theme => ({
    root: {
      padding: "2rem",
      display: "flex",
    },
    textContainer: {
        paddingBottom: "2rem",
    },
    linkContainer: {
        display: "flex",
        justifyContent: "space-between"
    }
}))

//volid72733@posiklan.com
//nemtudom

const NotVerified = (props) => {

    const classes = useStyles()

    const handleCheckAgainClick = () => {
        props.reloadUser()
    }

    const handleResendVerificationEmailClick = () => {
        props.sendEmailVerification()
    }

    return (
        <Page>
            <Panel
                title="Verification needed!">
                <Grid container className={classes.root} >
                    <Grid item xs={12} className={classes.textContainer}>
                        Your Print Princess account has been created. Please check your email for the activation link.
                    </Grid>
                    <Grid item xs={12} className={classes.linkContainer}>
                        <PPButton simple onClick={handleResendVerificationEmailClick}>Resend verification email</PPButton>
                        <PPButton onClick={handleCheckAgainClick} autoFocus>Try login again</PPButton>
                    </Grid>
                </Grid>
            </Panel>
        </Page>
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        reloadUser: () => dispatch(ACTIONS.reloadUser()),
        sendEmailVerification: () => dispatch(ACTIONS.sendEmailVerification())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotVerified)