import React, {useState} from 'react'
import * as COM from "../../misc/common"

import { 
    makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative"
    },
    icon: {
        position: "absolute",
        left: "1rem",
        right: 0,
        height: "2.5rem",
        width: "1.5rem",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    input: {
        width: "100%",
        height: "2.5rem",
        backgroundColor: theme.printPrincess.colors.inputBackground,
        boxShadow: theme.printPrincess.shadows.input,
        borderRadius: "1.25rem",
        border: "solid 1px rgba(255, 255, 255, 0)",
        fontWeight: "500",
        fontSize: "1rem",
        lineHeight: "150%",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        color: theme.printPrincess.colors.numbered[2],
        "&:focus": {
          outline: "none",
          border: `solid 1px ${theme.printPrincess.colors.numbered[4]}`
        },
    },
    fullWidth: {
        width: "100%",
    },
    iconPresented: {
        "& input": {
            paddingLeft: "3rem !important"
        },
        "& div svg": {
            fill: theme.printPrincess.colors.numbered[4]
        }
    }
}))

const PPInput = (props) => {

    const classes = useStyles()
    const classBuilder = new COM.ClassBuilder(classes.root)
    const rnd = `${`${Math.random()}`.replace(".","")}`
    const [id] = useState(`${props.id}-${rnd}`)

    if (props.fullWidth) { classBuilder.add(classes.fullWidth) }
    if (props.startIcon) { classBuilder.add(classes.iconPresented) }
    
    const StartIcon = () => {
        if (!props.startIcon) return null
        return (
            <div className={classes.icon}>
                {props.startIcon}
            </div>
        )
    }

    return (
        <div className={classBuilder.asString()}>
            <StartIcon />
            <input
                readOnly={props.readOnly}
                value={props.value}
                className={classes.input}
                onChange={props.onChange}
                autoComplete={props.autocompleteOff ? `${rnd}` : "on"}
                placeholder={props.placeholder}
                type={props.type}
                id={id} />
        </div>
    )
}

export default PPInput