import React from 'react'
import { connect } from "react-redux"
import * as ACTIONS from "../../redux/actions"
import * as ROUTES from '../../misc/routes'

import {
    Link,
    makeStyles
} from '@material-ui/core'

import Panel from '../Panel/Panel'
import Page from '../App/Page'

import DualPanelContainer from './DualPanelContainer'
import SocialButtons from './SocialButtons'
import Inputs from './Inputs'

const useStyles = makeStyles(theme => ({

    linkListContainer: {
        display: "flex",
        marginTop: ".5rem",
        marginBottom: ".5rem",
        padding: 0,
        fontSize: "0.75rem",
        listStyleType: "none",
         "& li": {
            margin: 0,
        },
        "& li:last-child": {
            paddingLeft: "1.5rem",
            "& a": {
                color: theme.printPrincess.colors.numbered[4],
            },
            "&:before": {
                content: "'|'",
                left: "-.75rem",
                position: "relative",
                color: theme.printPrincess.colors.numbered[4],
            }
        }
    },

    register: {
        fontWeight: "bold"
    },

    forgot: {
        color: theme.palette.text.secondary
    },

    [theme.breakpoints.up('sm')]: {
        buttonContainer: {
            marginTop: 0,
            marginBottom: 0,
        },
        right: {
            //
        },
    }
}))

const SignIn = (props) => {

    const classes = useStyles()

    const handleSignInClick= (email, password) => {
        props.signIn(email, password)
    }

    const handleSignUpClick = (ev) => {
        props.redirect(ROUTES.SIGN_UP)
    }

    const handlePasswordResetClick = (ev) => {
        props.redirect(ROUTES.FORGOT_PASSWORD)
    }

    const handleCloseClick = () => {
        props.redirect(ROUTES.HOME)
    }

    const bottom = (
        <ul className={classes.linkListContainer}>
            <li><Link onClick={handleSignUpClick}>Register now</Link></li>
            <li><Link onClick={handlePasswordResetClick}>Forgot password?</Link></li>
        </ul>
    )

    return (
        <Page
            showCastle
            showSlogen
            showDownloadBadges>
            <Panel
                title="Sign In"
                onClose={handleCloseClick}>
                <DualPanelContainer
                    left={<SocialButtons textPrefix="Sign In" />}
                    right={<Inputs buttonLabel="Sign in" handleClick={handleSignInClick} autocompleteOff />}
                    bottom={bottom} />
            </Panel>
        </Page>
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        signIn: (username, password) => dispatch(ACTIONS.signIn(username, password)),
        redirect: (to) => dispatch(ACTIONS.redirect(to)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)