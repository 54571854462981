import React from 'react'

import TextPage from "../App/TextPage"
import MailTo from '../App/MailTo'

const DevelopmentInProgress = (props) => {
    return (
        <TextPage title="In progress">
            <p>
                The Linux and Windows agents are not ready at the moment. We are working hard to finish both variants, but we are a very small team, so we have limited resources.
            </p>
            <p>
                <MailTo>Please let us</MailTo> know which variant would be essential for you.
            </p>
        </TextPage>
    )
}

export default DevelopmentInProgress

