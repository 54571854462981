
import React from 'react'

const UploadToCloud = (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={props.style}>
            <path d="M259.6,225.7c-1.6-2-4.4-2.3-6.4-0.8c-0.3,0.2-0.6,0.5-0.8,0.8l-64,81c-1.6,2-1.2,4.8,0.8,6.4c0.8,0.6,1.8,1,2.8,1h42.2
                v138.5c0,2.5,2.1,4.6,4.6,4.6h34.3c2.5,0,4.6-2.1,4.6-4.6V314.1H320c3.8,0,5.9-4.4,3.6-7.4L259.6,225.7z" />
            <path d="M427.1,173c-26.2-69-92.9-118.1-171-118.1s-144.8,49-171,118.1C36.2,185.8,0,230.4,0,283.4c0,63.1,51.1,114.3,114.2,114.3
                h22.9c2.5,0,4.6-2.1,4.6-4.6v-34.3c0-2.5-2.1-4.6-4.6-4.6h-22.9c-19.3,0-37.4-7.7-50.9-21.5c-13.4-13.8-20.6-32.5-19.9-51.8
                c0.5-15.1,5.7-29.3,15-41.2c9.5-12.2,22.9-21,37.8-25l21.7-5.7l7.9-20.9c4.9-13,11.8-25.2,20.4-36.2c8.5-10.9,18.6-20.5,29.9-28.5
                c23.5-16.5,51.1-25.3,80-25.3c28.9,0,56.5,8.7,80,25.3c11.4,8,21.4,17.6,29.9,28.5c8.6,11,15.5,23.3,20.4,36.2l7.9,20.9l21.6,5.7
                c31,8.3,52.6,36.5,52.6,68.6c0,18.9-7.4,36.7-20.7,50.1c-13.2,13.3-31.3,20.8-50.1,20.7h-22.9c-2.5,0-4.6,2.1-4.6,4.6v34.3
                c0,2.5,2.1,4.6,4.6,4.6h22.9c63.1,0,114.2-51.1,114.2-114.3C512,230.5,475.9,185.9,427.1,173z" />
        </svg>
)

export default UploadToCloud