import React, { useState } from 'react'
import { connect } from "react-redux"
import * as ACTIONS from "../redux/actions"

import { 
    Grid,
    Link,
    makeStyles,
} from '@material-ui/core'

import Panel from './Panel/Panel'
import Page from './App/Page'
import PPButton from './App/PPButton'
import PPInput from './App/PPInput'

import * as ROUTES from '../misc/routes'

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: "1rem",
        marginLeft: "auto",
        marginRight: "auto",
        alignItems: "center",
    },
    back: {
        fontSize: "0.75rem",
    },
    inputContainer: {
        marginBottom: "7rem",
    },
    [theme.breakpoints.up('sm')]: {
        container: {
            maxWidth: "70%"
        }
    },
}))

const ForgotPassword = (props) => {

    const classes = useStyles()
    const [email, setEmail] = useState("")

    const handleSendClick = (ev) => {
        props.sendPasswordResetEmail(email)
    }

    const handleEmailChange = (ev) => {
        setEmail(ev.target.value)
    }

    const handleBackClick = (ev) => {
        props.redirect(ROUTES.SIGN_IN)
    }

    return (
        <Page>
            <Panel
                title="Forgot Password"
                onClose={handleBackClick}>
                <Grid container className={classes.container}>
                    <Grid item xs={12} className={classes.inputContainer}>
                        <PPInput
                            disableUnderline
                            fullWidth
                            placeholder="Email address"
                            onChange={handleEmailChange}
                            id="input-email" />
                    </Grid>
                    <Grid item xs={6} className={classes.back}>
                        <Link onClick={handleBackClick}>Back to sign in</Link> 
                    </Grid>
                    <Grid item xs={6}>
                        <PPButton
                            fullWidth
                            onClick={handleSendClick}>
                            Request
                        </PPButton> 
                    </Grid>
                </Grid>
            </Panel>
        </Page>
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        sendPasswordResetEmail: (email) => dispatch(ACTIONS.sendPasswordResetEmail(email)),
        redirect: (to) => dispatch(ACTIONS.redirect(to)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)