import React from 'react'
import { connect } from "react-redux"
import * as ACTIONS from "../../redux/actions"
import * as COM from "../../misc/common"

import {
    makeStyles
} from '@material-ui/core'

import FacebookIcon from '../Icons/FacebookIcon'
import GoogleIcon from '../Icons/GoogleIcon'
import AppleIcon from '../Icons/AppleIcon'

const useStyles = makeStyles(theme => ({
    root: {
        "& div:nth-child(2n)": {
            marginTop: "2rem",
            marginBottom: "2rem"
        },
        "& div:last-child": {
            marginBottom: "1.5rem"
        },
    },
    button: {
        height: "2rem",
        width: "100%",
        paddingLeft: "1rem",
        borderRadius: "1rem",
        border: "none",
        fontWeight: 500,
        fontSize: theme.printPrincess.fonts.normal,
        color: theme.printPrincess.colors.white,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        boxShadow: "-12px -12px 20px rgba(255, 255, 255, 0.8), 10px 10px 20px rgba(166, 180, 200, 0.7);",
        "&:hover": {
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "0px 10px 10px rgba(166, 180, 200, 0.7)",
        },
        "& svg": {
            fill: theme.printPrincess.colors.white,
        },
        "& div": {
            width: "1.25rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        }
    },
    
    google: {
        background: "#DB4437",
    },
    apple: {
        background: "black",
    },
    facebook: {
        background: "#3b5998",
    },

    googleIcon: {
        "& svg": {
            width: "1rem"
        }
    },
    appleIcon: {
        "& svg": {
            width: "0.75rem"
        }
    },
    facebookIcon: {
        "& svg": {
            width: "0.5rem"
        }
    },

    textFormatter: {
        textTransform: "capitalize",
        "& span": {
            textTransform: "lowercase"
        }
    },
}))

const SocialButtons = (props) => {
  
    const classes = useStyles()
    const { textPrefix } = props

    const TextFormatter = (props) => (
        <span className={classes.textFormatter}>{props.children}</span>
    )

    const handleGoogleClick = (ev) => {
        props.signInWithGoogle()
    }

    const handleAppleClick = (ev) => {
       props.signInWithApple()
    }

    const handleFacebookClick = (ev) => {
        props.signInWithFacebook()
    }

    const SocialButton = (props) => {

        const providerClass = classes[props.provider]
        const iconClass = classes[`${props.provider}Icon`]

        return (
            <button onClick={props.onClick} className={COM.cls(classes.button, providerClass)}>
                <div className={iconClass}>{props.startIcon}</div>
                {props.children}
            </button>
        )
    }

    return (
        <div className={classes.root}>
            <div>
                <SocialButton
                    onClick={handleFacebookClick}
                    provider="facebook"
                    startIcon={<FacebookIcon />}>
                    <TextFormatter>{textPrefix} <span>with</span> Facebook</TextFormatter>
                </SocialButton>
            </div>
            <div>
                <SocialButton
                    onClick={handleGoogleClick}
                    provider="google"
                    startIcon={<GoogleIcon />} >
                    <TextFormatter>{textPrefix} <span>with</span> Google</TextFormatter>
                </SocialButton>
            </div>
            <div>
                <SocialButton
                    onClick={handleAppleClick}
                    provider="apple"
                    startIcon={<AppleIcon />} >
                    <TextFormatter>{textPrefix} <span>with</span> Apple</TextFormatter>
                </SocialButton>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        signInWithGoogle: () => dispatch(ACTIONS.signInWithGoogle()),
        signInWithFacebook: () => dispatch(ACTIONS.signInWithFacebook()),
        signInWithApple: () => dispatch(ACTIONS.signInWithApple()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialButtons)
