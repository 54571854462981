import React from 'react';

const GoogleIcon = (props) => {

    return (
        <svg {...props} viewBox="0 0 23 14" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 11V8H23V6H20V3H18V6H15V8H18V11H20ZM7 3C8.3051 3 9.50008 3.5235 10 4L12 2C10.7928 0.866584 8.88566 3.1968e-07 7 3.1968e-07C3.15496 -0.00115623 0 3.13598 0 7C0 10.864 3.15496 14 7 14C11.0236 14 14 10.997 14 7C14 6.41826 14 6.5 14 6H7V8H11C10.818 9.09988 9.67011 11 7 11C4.69274 11 3 9.3582 3 7C3 4.63833 4.69274 3 7 3Z" />
        </svg>
    )
}

export default GoogleIcon