import React from 'react'
import { connect } from "react-redux"
import * as ACTIONS from "../../redux/actions"
import * as ROUTES from '../../misc/routes'

import { 
    makeStyles,
} from '@material-ui/core'

import Panel from '../Panel/Panel'
import Page from './Page'

const useStyles = makeStyles(theme => ({
    root: {
        padding: "1rem",
        paddignTop: "2rem"
    },
}))

const TextPage = (props) => {

    const classes = useStyles()

    const handleBackClick = (ev) => {
        props.redirect(ROUTES.HOME)
    }

    return (
        <Page>
            <Panel title={props.title} onClose={handleBackClick}>
                <div className={classes.root}>
                    {props.children}
                </div>
            </Panel>
        </Page>
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        redirect: (to) => dispatch(ACTIONS.redirect(to)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TextPage)