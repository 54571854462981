import { connect } from "react-redux"

const Username = (props) => props.state.user.displayName || "Anonymous"

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        //
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Username)