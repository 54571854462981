import React from 'react'

import SvgIcon from '@material-ui/core/SvgIcon'

const BetaIcon = (props) => {

    return (
        <SvgIcon {...props} viewBox="0 0 46 30">
            <path d="M44.2857,0 L1.42857,0 C0.571429,0 0,0.571429 0,1.42857 L0,21.4286 C0,22.2857 0.571429,22.8571 1.42857,22.8571 L7.14286,22.8571 L7.14286,28.5714 C7.14286,29.1429 7.42857,29.7143 8,29.8571 C8.14286,30 8.28572,30 8.57143,30 C9,30 9.28572,29.8571 9.57143,29.5714 L16.2857,22.8571 L44.2857,22.8571 C45.1429,22.8571 45.7143,22.2857 45.7143,21.4286 L45.7143,1.42857 C45.7143,0.571429 45,0 44.2857,0 Z M33.714234,12.714357 L36.142808,12.714357 L34.999948,9.571502 L33.714234,12.714357 Z M11.714288,12 L9,12 L9,14.142857 L11.285718,14.142857 C11.857148,14.285717 12.428568,13.857137 12.571428,13.285717 C12.714288,12.714285 12.285718,12.142857 11.714288,12 Z M12.142858,9.571411 C12.142858,8.999987 11.714288,8.571411 11,8.571411 L9,8.571411 L9,10.428557 L11,10.428557 C11.714288,10.571411 12.142858,10.142837 12.142858,9.571411 Z M11.8571,15.8571 L7.28571,15.8571 L7.28571,7.28572 L11.5714,7.28572 C12.8571,7.14286 14,8 14.1429,9.28571 L14.1429,9.42857 C14.1429,10.2857 13.7143,11 13,11.2857 C13.8571,11.5714 14.5714,12.4286 14.5714,13.4286 C14.4286,14.8571 13.28571,15.8571 11.8571,15.8571 Z M22.5714,8.85714 L17.8571,8.85714 L17.8571,10.5714 L22.1429,10.5714 L22.1429,12.2857 L17.8571,12.2857 L17.8571,14.1429 L22.5714,14.1429 L22.5714,15.8571 L16,15.8571 L16,7.28572 L22.5714,7.28572 L22.5714,8.85714 Z M28,15.8571 L26.1429,15.8571 L26.1429,9 L23.4286,9 L23.4286,7.28572 L30.5714,7.28572 L30.5714,9 L28,9 L28,15.8571 Z M37.5714,15.8571 L37,14.4286 L33.1429,14.4286 L32.5714,15.8571 L30.5714,15.8571 L34,7.28572 L36,7.28572 L39.5714,15.8571 L37.5714,15.8571 Z" id="Shape"></path>
        </SvgIcon>
    )
}

export default BetaIcon
