import React from 'react'
import { connect } from "react-redux"
import * as COM from '../../misc/common'

import { 
    IconButton,
    makeStyles,
    Typography
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/CloseRounded'

import SignInInProgress from '../Sign/SignInInProgress'

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    header: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        "& h2": {
            flex: 1,
            textAlign: "center",
        }
    },
    title: {
        fontWeight: 300,
        lineHeight: "115%",     
        textAlign: "center",
        color: theme.printPrincess.colors.numbered[2]
    },
    normal: {
        fontSize: "2.5rem",
    },
    small: {
        fontSize: "1.5rem",
    },
    children: {
        flex: 1,
        width: "100%",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    paper: {
        position: "absolute",
        top: "calc(-3.5rem + 1.25vw)",
        left: "clamp(42.5%, 42.5vw, 48%)",
        transform: "scale(0.4)",
    },

    [theme.breakpoints.up('md')]: {
        paper: {
            transform: "scale(1)",
            top: "-3rem",
            left: "55%",
        },
    }
}))

const Close = (props) => {

    //const classes = useStyles()

    if (!props.onClick) return null

    return (
        <>
            <IconButton onClick={props.onClick} size="small">
                <CloseIcon fontSize="inherit" />
            </IconButton>
        </>
    )
}

const PaperRoll = (props) => {

    const classes = useStyles()

    if (!props.show) return null

    return <img src="img/paper.png" className={classes.paper} alt="Paper roll" />
}

const Panel = (props) => {

    const classes = useStyles()
    const state = props.state
    const { signInInProgress } = state
    const title = props.title
    const titleSizeClass = title.length > 12 ? classes.small : classes.normal

    if (signInInProgress) return <SignInInProgress />   

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <PaperRoll show={props.showPaper} />
                <Typography component="h2" className={COM.cls(classes.title, titleSizeClass)}>{title}</Typography>
                <Close onClick={props.onClose}/>
            </div>
            <div className={classes.children}>
                {props.children}
            </div>
        </div>
    )
}


const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        //
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Panel)

