import LinearProgress from "@material-ui/core/LinearProgress";

import {
    withStyles,
} from '@material-ui/core/'

const CustomLinearProgress = withStyles(theme => ({
    root: {
      height: "0.5rem",
      borderRadius: "0.25rem",
    },
    colorPrimary: {
        backgroundColor: theme.printPrincess.colors.inputBackground,
        boxShadow: theme.printPrincess.shadows.input,
    },
    bar: {
      borderRadius: "0.25rem",
      backgroundColor: theme.palette.secondary.main,
    }
}))(LinearProgress)

export default CustomLinearProgress