import React from 'react'
import MailTo from './MailTo'

import { 
  makeStyles,
} from '@material-ui/core/'

import BetaIcon from '../Icons/BetaIcon'
import * as COM from '../../misc/common'

const useStyles = makeStyles((theme) => ({
  '@global': {
    header: {
      padding: "1rem",
      justifyContent: "center",
      alignItems: "center",
      color: theme.printPrincess.colors.numbered[1],
      display: "none",
    },
  },
  betaIcon: {
    marginRight: "0.5rem"
  },
  
  [theme.breakpoints.up('md')]: {
    '@global': {
      header: {
        display: "flex",
      },
    }
  }
}))

const showBetaHeader = String(process.env.REACT_APP_SHOW_BETA_HEADER).toLocaleUpperCase()

const Header = (props) => {
   
    const classes = useStyles()

    if (showBetaHeader !== COM.YES) return null

    return (
    <header>
        <BetaIcon className={classes.betaIcon} />
        You are using a BETA release, so if something doesn't work well,&nbsp;<MailTo color="red" isOnHeader>please let us to know!</MailTo>
    </header>
    )

}

export default Header