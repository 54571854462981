import React from 'react';

const AppleIcon = (props) => {

    return (
        <svg {...props} viewBox="0 0 15 19" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5284 9.77724C12.5375 9.07254 12.7252 8.38159 13.074 7.76867C13.4229 7.15575 13.9215 6.64078 14.5236 6.27168C14.1411 5.72697 13.6366 5.2787 13.05 4.96246C12.4634 4.64623 11.8109 4.47075 11.1444 4.44996C9.72266 4.30114 8.34433 5.29833 7.61983 5.29833C6.88131 5.29833 5.76583 4.46473 4.56464 4.48938C3.78768 4.51441 3.03046 4.73971 2.36678 5.14333C1.7031 5.54696 1.15558 6.11513 0.777572 6.7925C-0.85988 9.61956 0.361511 13.7744 1.93005 16.0596C2.71483 17.1785 3.632 18.4285 4.83211 18.3842C6.00649 18.3356 6.44509 17.6374 7.86267 17.6374C9.26708 17.6374 9.67859 18.3842 10.903 18.356C12.1632 18.3356 12.9572 17.232 13.7144 16.1024C14.2783 15.3051 14.7122 14.4239 15 13.4914C14.2679 13.1827 13.6431 12.6658 13.2036 12.0053C12.7641 11.3448 12.5292 10.5699 12.5284 9.77724Z"/>
            <path d="M10.2157 2.94712C10.9028 2.1246 11.2413 1.06739 11.1593 0C10.1096 0.109945 9.13992 0.61024 8.44354 1.4012C8.10307 1.78761 7.8423 2.23714 7.67614 2.7241C7.50999 3.21107 7.44171 3.72591 7.47521 4.23921C8.00026 4.2446 8.51969 4.13112 8.99438 3.9073C9.46908 3.68349 9.88666 3.35519 10.2157 2.94712Z" />
        </svg>
    )
}

export default AppleIcon