import React from 'react'
import { connect } from "react-redux"
import * as ACTIONS from "../../redux/actions"
import * as ROUTES from '../../misc/routes'

import theme from '../../theme/default.json'

import {
    makeStyles,
    Grid,
} from '@material-ui/core/'
  
const useStyles = makeStyles((theme) => ({
    root: {
        margin: "2rem 0 2rem 0",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    button: {
        transform: "scale(.85)",
        margin: "1rem",
        backgroundColor: theme.printPrincess.colors.numbered[6],
        border: "none",
        borderRadius: "0.5rem",
        boxShadow: "-0.25rem -0.25rem 0.5rem #2FA76B, 0.25rem 0.25rem 0.5rem #1B5E3C",
        "&:hover": {
            boxShadow: "none",
        },
        "&:active": {
            boxShadow: "0.25rem 0.25rem 0.5rem #1B5E3C",
        }
    },

    [theme.breakpoints.up('sm')]: {
        button: {
            transform: "scale(1)",
        },
    },

    [theme.breakpoints.up('md')]: {
        root: {
            flexDirection: "row"
        },
    }
}))

const Buttons = (props) => {

    const classes = useStyles(theme)
    const { show, redirect } = props

    if (!show) return null

    const handleMacosClick = (ev) => {
        redirect(ROUTES.APPLE_APP_STORE)
    }

    const handleLinuxClick = (ev) => {
        redirect(ROUTES.DEVELOPMENT_IN_PROGRESS)
    }

    const handleWindowsClick = (ev) => {
        redirect(ROUTES.DEVELOPMENT_IN_PROGRESS)
    }

    return (
        <>
            <button className={classes.button}><img src="img/macos.svg" alt="Download for macOS" onClick={handleMacosClick}/></button>
            <button className={classes.button}><img src="img/linux.svg" alt="Download for Linux" onClick={handleLinuxClick}/></button>
            <button className={classes.button}><img src="img/windows.svg" alt="Download for Windows" onClick={handleWindowsClick}/></button>
        </>
    )
}

const DownloadBadges = (props) => {

    const classes = useStyles(theme)
    const { show, redirect } = props

    return (
        <Grid container className={classes.root}>
            <Buttons show={show} redirect={redirect} />
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        redirect: (to) => dispatch(ACTIONS.redirect(to)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadBadges)