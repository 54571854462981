import React from 'react'

import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core'

import PPButton from '../App/PPButton'

const useStyles = makeStyles(theme => ({
    root: {
        "& > div > div": {
            padding: "1rem 5rem 1rem 5rem",
            borderRadius: "2.5rem !important",
        }
    },
    content: {
        padding: "1rem 0rem 1rem 0rem",
        "& *": {
            color: theme.printPrincess.colors.numbered[2],
        }
    },
    title: {
        "& h2": {
            fontWeight: 300,
            fontSize: "2rem",
        },
        textAlign: "center",
        color: theme.printPrincess.colors.numbered[2],   
    },
    actions: {
        padding: "1rem 0rem 2rem 0rem",
        display: "flex",
        alignItems: "space-between",
        justifyContent: "space-between",
    }
}))

const AreYouSureDialog = (props) => {

    const classes = useStyles()

    const open = props.open
    const title = props.title
    const onDecline = props.onDecline
    const onAccept = props.onAccept

    return (
        <Dialog
            open={open}
            onClose={onDecline}
            aria-labelledby="key-dialog-title"
            aria-describedby="key-dialog-description"
            className={classes.root}>
            
            <DialogTitle id="key-dialog-title" className={classes.title}>
                    {title}
            </DialogTitle>
            
            <DialogContent className={classes.content}>
                <DialogContentText id="key-dialog-description">
                    {props.children}
                </DialogContentText>
            </DialogContent>
            
            <DialogActions className={classes.actions}>
                <PPButton
                    simple
                    minWidth="8rem"
                    onClick={onDecline}
                    autoFocus>
                    No
                </PPButton>
                <PPButton
                    minWidth="8rem"
                    onClick={onAccept}
                    color="secondary">
                    Yes
                </PPButton>
            </DialogActions>
        </Dialog>
    )
}

export default AreYouSureDialog