export default class Flash {

    //These are used as classnames, so keep them lower-case
    static ERROR = "error"
    static WARNING = "warning"
    static INFO  = "info"
    static SUCCESS = "success"
    static GENERAL = "general"

    severity
    title
    messages
    payload             //Object for storing an exception or any other object realted to the Flash

    constructor (severity = this.ERROR, title, messages, payload) {
        this.severity = severity
        this.title = title
        this.messages = Array.isArray(messages) ? [...messages] : [messages]
        this.payload = payload
    }
}