import React from 'react'

import {
    makeStyles,
    Typography,
    LinearProgress,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        width: "100%",
        flexDirection: "column",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    progress: {
        marginTop: "1rem",
        marginBottom: "1rem",
        width: "75%"
    }
}))

const SignInInProgress = (props) => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Typography varian="h4" color="secondary">Authentication is in progress</Typography>
            <LinearProgress color="secondary" className={classes.progress} />
        </div>
    )
}

export default SignInInProgress