import React from 'react'

import CustomLinearProgress from './CustomLinearProgress'
import Working from './Working'

const BackendWorking = (props) => {

    return (
        <Working
            top={props.filename}
            center={<CustomLinearProgress />}
            bottomLeft={`File is on processing in the cloud...`}
            bottomRight={``}
        />
    )
}

export default BackendWorking