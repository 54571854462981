import React from 'react'
import { connect } from "react-redux"
import * as C from '../../misc/common'

import BackendWorking from './BackendWorking'
import FrontendWorking from './FrontendWorking'

const OperationStatus = (props) => {

    const operationStatus = props.state.operationStatus
    const file = props.state.file

    switch (operationStatus) {
        case C.OPERATION_WAITING_FOR_BACKEND: return <BackendWorking filename={file.name} />
        case C.OPERATION_FRONTEND_IS_WORKING: return <FrontendWorking />
        default: return null //C.OPERATION_IDLE and any other case
    }
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
       //
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperationStatus)