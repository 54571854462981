import * as ACTION_TYPES from "./actionTypes"

// FILE
export const fileDrop = file => ({
  type: ACTION_TYPES.FILE_DROP,
  payload: file
})

export const setFile = file => ({
  type: ACTION_TYPES.SET_FILE,
  payload: file
})

export const deleteFile = () => ({
  type: ACTION_TYPES.DELETE_FILE,
})

export const setDragInfo = value => ({
  type: ACTION_TYPES.SET_DRAG_INFO,
  payload: value
})

export const setDragStatus = value => ({
  type: ACTION_TYPES.SET_DRAG_STATUS,
  payload: value
})


// UI
export const setOperationStatus = status => ({
  type: ACTION_TYPES.SET_OPERATION_STATUS,
  payload: status
})


// UPLOAD
export const setUploadProgress = progress => ({
  type: ACTION_TYPES.SET_UPLOAD_PROGRESS,
  payload: progress
})


// REMOTE OBJECTS
export const setRemoteFileInfo = info => ({
  type: ACTION_TYPES.SET_REMOTE_FILE_INFO,
  payload: info
})

export const setDocumentKey = key => ({
  type: ACTION_TYPES.SET_DOCUMENT_KEY,
  payload: key
})

export const generateDocumentKey = () => ({
  type: ACTION_TYPES.GENERATE_DOCUMENT_KEY,
})


// AUTH
export const authStateChange = user => ({
  type: ACTION_TYPES.AUTH_STATE_CHANGE,
  payload: user
})

export const signIn = (username, password) => ({
  type: ACTION_TYPES.SIGN_IN,
  payload: {
    username: username,
    password: password
  }
})

export const setSignInInProgressFlag = (value) => ({
  type: ACTION_TYPES.SET_SIGN_IN_PROGRESS_FLAG,
  payload: value,
})

export const signInWithGoogle = () => ({
  type: ACTION_TYPES.SIGN_IN_WITH_GOOGLE
})

export const signInWithFacebook = () => ({
  type: ACTION_TYPES.SIGN_IN_WITH_FACEBOOK
})

export const signInWithApple = () => ({
  type: ACTION_TYPES.SIGN_IN_WITH_APPLE
})

export const signInSuccess = () => ({
  type: ACTION_TYPES.SIGN_IN_SUCCESS,
})

export const signOut = () => ({
  type: ACTION_TYPES.SIGN_OUT,
})

export const createUser = (email, password) => ({
  type: ACTION_TYPES.CREATE_USER,
  payload: {
    email: email,
    password: password
  }
})

export const reloadUser = () => ({
  type: ACTION_TYPES.RELOAD_USER
})

export const createUserSuccess = () => ({
  type: ACTION_TYPES.CREATE_USER_SUCCESS
})

export const sendEmailVerification = () => ({
  type: ACTION_TYPES.SEND_EMAIL_VERIFICATION
})

export const sendPasswordResetEmail = (email) => ({
  type: ACTION_TYPES.SEND_PASSWORD_RESET_EMAIL,
  payload: email
})

export const sendEmailVerificationSuccess = () => ({
  type: ACTION_TYPES.SEND_EMAIL_VERIFICATION_SUCCESS,
})

export const deleteUser = (password) => ({
  type: ACTION_TYPES.DELETE_USER,
  password: password
})


// APPLICATION
export const applicationDidFinish = () => ({
  type: ACTION_TYPES.APPLICATION_DID_FINISH,
})

export const applicationDidLaunch = () => ({
  type: ACTION_TYPES.APPLICATION_DID_LAUNCH
})


// FIREBASE LISTENERS
export const startUserDataListener = () => ({
  type: ACTION_TYPES.START_USER_DATA_LISTENER
})

export const stopUserDataListener  = () => ({
  type: ACTION_TYPES.STOP_USER_DATA_LISTENER
})

export const startDocumentDataListener = () => ({
  type: ACTION_TYPES.START_DOCUMENT_DATA_LISTENER
})

export const stopDocumentDataListener = () => ({
  type: ACTION_TYPES.STOP_DOCUMENT_DATA_LISTENER
})


// REDIRECT
export const redirect = (to) => ({
  type: ACTION_TYPES.REDIRECT,
  payload: to
})

// FLASH
export const flash = (payload) => ({
  type: ACTION_TYPES.FLASH,
  payload: payload
})

export const clearFlash = () => ({
  type: ACTION_TYPES.CLEAR_FLASH
})