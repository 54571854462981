import moment from 'moment'

import React from 'react'
import { connect } from "react-redux"
import * as ACTIONS from "../../redux/actions"

import {
    makeStyles,
} from '@material-ui/core/'

import DeleteIcon from '@material-ui/icons/DeleteOutline'
import MimeTypeIcon from './MimeTypeIcon'

const prettyBytes = require('pretty-bytes')

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "1rem",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& div": {
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
        }
    },
    size: {
        color: theme.printPrincess.colors.numbered[2]
    }
}))


const RemoteFileInfo = (props) => {

    const fileInfo = props.state.remoteFileInfo
    const classes = useStyles()

    const handleDeleteClick = (e) => {
        props.deleteFile()
    }

    // no remote file
    if (!fileInfo || !fileInfo.size || fileInfo.deleted) return null

    return (
        <div className={classes.root}> 
            <div>
                <MimeTypeIcon type={fileInfo.contentType} />
                {moment.unix(fileInfo.timestamp.seconds).format("MM/DD/YYYY")}
            </div>
            <div>
                <span className={classes.size}>{prettyBytes(fileInfo.size)}</span>
                <DeleteIcon onClick={handleDeleteClick}/>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        deleteFile: () => dispatch(ACTIONS.deleteFile()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoteFileInfo)