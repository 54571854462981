import React from 'react';

const FacebookIcon = (props) => {

    return (
        <svg {...props} viewBox="0 0 11 21" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 21H7V12H11V8H7V6C7 5.70687 6.80157 5.20728 7 5C7.19843 4.79272 7.71937 4 8 4H11V0H8C6.59687 0 4.99216 0.963615 4 2C3.00784 3.03639 3 4.53433 3 6V8H0V12H3V21Z" />
        </svg>
    )
}

export default FacebookIcon