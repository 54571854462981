// Redux imports
import { createStore, applyMiddleware, compose } from 'redux'
import { logger } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import reducer from './reducer'
import sagas from './sagas'

// Create saga middleware
const saga = createSagaMiddleware()

// Create middlewares array
const middlewares = [saga]

// We need the logger only if development mode
if (process.env.NODE_ENV === 'development') {
    middlewares.push(logger)
    console.debug(`${process.env.NODE_ENV} mode. Redux logger has been added`)   
} else {
    console.debug(` ${process.env.NODE_ENV} mode. Redux logger has NOT been added`)
}

// Create Redux store and add middlewares
const store = createStore(
    reducer,
    compose(applyMiddleware(...middlewares))
)

// Init sagas
// NOTE: IMPORTANT: we have to call this after the createStore method!
saga.run(sagas)

export default store