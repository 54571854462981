import React from 'react'

import { 
  makeStyles,
} from '@material-ui/core/'

import theme from '../../theme/default.json'

import * as COM from '../../misc/common'

import DownloadBadges from './DownloadBadges'
import NavBar from './NavBar'

const useStyles = makeStyles((theme) => ({

  root: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  layers: {
    minHeight: "clamp(250px, 55vw ,400px)",
    maxHeight: "1000px",
    height: 0,
    width: "100%",
  },

  inner: { 
    position: "absolute",
    top: "0",
    left: "0"
  },

  distant: {
    position: "relative",
    height: 0,
    backgroundSize: "cover",
    backgroundImage: "url(img/bg1.jpg)"
  },

  slogen: { 
    textAlign: "center",
    marginTop: "5rem",
    textTransform: "uppercase",
    fontWeight: 800,
    fontSize: theme.printPrincess.fonts.normal,
    visibility: "hidden",
  },
  
  castle: {
    backgroundPositionY: "top",
    backgroundPositionX: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: "url(img/castle.png)",
    backgroundSize: "auto 100%",
  },

  near: {
    position: "absolute",
    backgroundSize: "cover",
    backgroundPositionY: "top",
    backgroundRepeat: "no-repeat",
    backgroundImage: "url(img/bg2.png)"
  },

  gradient: {
    position: "absolute",
    bottom: "0",
    left: "0",
    height: "2rem",
    width: "100%",
    background: theme.printPrincess.gradients.landscapeFadeOut,
  },

  pageContainer: {
    position: "relative",
    margin: "auto",
    top: "-2rem",
    marginBottom: "-2rem",
    minWidth: "320px",
    maxWidth: "750px",
  },

  page: {
    flex: 1,
    width: "100%",
    padding: '1rem',
    minHeight: "12rem",
    borderRadius: '2.5rem',
    boxShadow: "0px 0px 24px rgba(0, 0, 0, 0.3), 10px 10px 24px rgba(102, 133, 105, 0.7)", //TODO: from JSON!
    backgroundColor: theme.printPrincess.colors.white,
    color: theme.printPrincess.colors.text,
    display: "flex",
    flexDirection: "column"
  },

  [theme.breakpoints.up('md')]: {
    slogen: {
      fontSize: theme.printPrincess.fonts.big,
      visibility: "visible",
    },
    layers: {
      height: "100%",
    },
    distant: {
      height: "100vh",
    },
    castle: {
      backgroundSize: "auto"
    },
    pageContainer: {
      top: "-22rem",
      marginBottom: "-22rem",
    },
    page: {
      minHeight: "23rem",
    }
  },

}))

const Page = (props) => {

    const classes = useStyles(theme)

    return (
      <div className={classes.root}>
                
        <div className={COM.cls(classes.distant, classes.layers)}>
          <div className={COM.cls(classes.layers, classes.inner)}>
            <NavBar />
            <div className={COM.cls(classes.layers, classes.inner, classes.slogen)}>
              <h2>She prints to you</h2>
            </div>
            <div className={COM.cls(classes.layers, classes.inner, classes.near)}>
              <div className={COM.cls(classes.layers, classes.inner, classes.castle)}>
                <div className={classes.gradient}></div>
              </div>
            </div>
          </div>
        </div>

        <div className={classes.pageContainer}>
          <div className={classes.page}>
              {props.children}
          </div>
          <DownloadBadges show={props.showDownloadBadges} />
        </div>

      </div>

  )
}
  
export default Page