import React from 'react'
import { connect } from "react-redux"
import * as ACTIONS from "../../redux/actions"
import * as ROUTES from '../../misc/routes'

import {
    Link,
    makeStyles
} from '@material-ui/core'

import Panel from '../Panel/Panel'
import Page from '../App/Page'
import DualPanelContainer from './DualPanelContainer'
import SocialButtons from './SocialButtons'
import Inputs from './Inputs'

const useStyles = makeStyles(theme => ({
    linkContainer: {
        marginTop: ".5rem",
        fontSize: "0.75rem",
        float: "right",
        "& a": {
            fontWeight: "normal"
        },
        color: theme.printPrincess.colors.numbered[4],
    }
}))

const Bottom = (props) => {

    const classes = useStyles()
    const { onClick } = props

    return (
        <div className={classes.linkContainer}>
            Already have an account? <Link onClick={onClick}>Sign In</Link>
        </div>
    )
}

const SignUp = (props) => {
    
    const handleSignUpClick = (email, password) => {
        console.debug("Sign up clicked: ", email, password)
        props.createUser(email, password)
    }

    const handleClose = () => {
        console.debug("Close clicked at SignUp view.")
        props.redirect(ROUTES.SIGN_IN)
    }

    return (
        <Page
            showCastle
            showSlogen
            showDownloadBadges>
            <Panel
                title="Sign Up"
                onClose={handleClose}>
                <DualPanelContainer
                    left={<SocialButtons textPrefix="Sign up" />}
                    right={<Inputs buttonLabel="Sign Up" handleClick={handleSignUpClick} autocompleteOff />}
                    bottom={<Bottom onClick={handleClose} />}
                />
            </Panel>
        </Page>
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        redirect: (to) => dispatch(ACTIONS.redirect(to)),
        createUser: (email, password) => dispatch(ACTIONS.createUser(email, password)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)