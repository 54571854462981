import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { connect } from "react-redux"
import * as ROUTES from '../misc/routes'

const PrivateRoute = (props) => {

  const user = props.state.user
  const verified = props.state.user ? props.state.user.emailVerified || false : false
  const signedIn = props.state.user && props.state.user.emailVerified

  if (!signedIn) return <Redirect to={ROUTES.SIGN_UP} />
  if (user && !verified) return <Redirect to={ROUTES.NOT_VERIFIED} />
  if (user && verified) return <Route path={props.path}>{props.children}</Route>
}

const mapStateToProps = (state) => ({
  state: state
})

const mapDispatchToProps = dispatch => {
  return {
    // There is nothing here at the moment
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)