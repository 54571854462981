import React from 'react'

import { 
    makeStyles,
} from '@material-ui/core'
import { ClassBuilder } from '../../misc/common'

const useStyles = makeStyles(theme => ({
    root: {
        textDecoration: "underline",
        "&:hover": {
            
        }
    },
    default: {
        color: theme.printPrincess.colors.text,
    },
    header: {
        color: theme.printPrincess.colors.numbered[1],
    }
    
}))

const MailTo = ({children, isOnHeader}) => {
    const classes = useStyles()

    const classBuilder = new ClassBuilder()
    classBuilder.add(classes.root)
    if (!isOnHeader) { classBuilder.add(classes.default) } else classBuilder.add(classes.header)

    return <a href="mailto:init@starcat.dev" className={classBuilder.asString()}>{children}</a>
}
export default MailTo