import React from 'react'
import * as C from "../../misc/common"

import {
    Grid,
    makeStyles,
} from '@material-ui/core/'

const useStyles = makeStyles((theme) => ({
    root: {
        "& *": {
            fontWeight: "bold",
        }
    },
    spacer: {
        marginTop: "1rem"
    },
    top: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "0.25rem",
        marginTop: "1rem",
        marginBottom: "1rem",
        color: theme.palette.secondary.main
    },
    bottomRight: {
        display: "flex",
        justifyContent: "flex-end",
        color: theme.palette.secondary.main
    }
}))

const Working = (props) => {

    const classes = useStyles()

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.top}>{props.top}</Grid>
            <Grid item xs={12}>{props.center}</Grid>
            <Grid item xs={8} className={classes.spacer}>{props.bottomLeft}</Grid>
            <Grid item xs={4} className={C.cls(classes.spacer, classes.bottomRight)}>{props.bottomRight}</Grid>
        </Grid>
    )
}

export default Working