import React from 'react'

import GeneralFileIcon from '@material-ui/icons/InsertDriveFileOutlined'
import ImageIcon from '@material-ui/icons/ImageOutlined'
import DocumentIcon from '@material-ui/icons/DescriptionOutlined'

const MimeTypeIcon = (props) => {
    const type = props.type
    if (type.includes("image")) return <ImageIcon />
    if (type.includes("pdf")) return <DocumentIcon />
    return <GeneralFileIcon />
}

export default MimeTypeIcon