import React, { useEffect } from 'react';
import { Router } from 'react-router-dom' //NOTE: IMPORTANT: we have to use Router not BrowserRouter other any other variant! Only Router takes history property as argument!

import { connect } from "react-redux";
import * as ACTIONS from "../../redux/actions";

import {createTheme} from '@material-ui/core/'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from "@material-ui/core/CssBaseline";

import App from './App'
import theme from '../../theme/default.json'
import historyInstance from '../../misc/historyInstanceCreator'

const AppWrapper = (props) => {

  useEffect(() => {
    props.applicationDidLaunch()
    return () => {
      props.applicationDidFinish()
    }
  // eslint-disable-next-line
  }, [])

  return (
    <MuiThemeProvider theme={createTheme(theme)}>
      <CssBaseline />
      <Router history={historyInstance}>
          <App />
      </Router>
    </MuiThemeProvider>
  )
}

const mapStateToProps = state => ({
  state: state
})

const mapDispatchToProps = dispatch => {
  return {
    applicationDidLaunch: () => dispatch(ACTIONS.applicationDidLaunch()),
    applicationDidFinish: () => dispatch(ACTIONS.applicationDidFinish()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper)
