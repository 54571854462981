import { v4 as uuidv4 } from 'uuid'

import { takeEvery, spawn, fork, put, call, take, select, putResolve, cancelled, cancel  } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'

import Firebase from '../components/Firebase/firebase'
import historyInstance from '../misc/historyInstanceCreator'

import * as ACTIONS from "./actions"
import * as ACTION_TYPES from "./actionTypes"
import * as C from "../misc/common"

import Flash from "../components/Flash/Flash"
import * as ROUTES from "../misc/routes"

const FIREBASE = new Firebase()
const FIRESTORE = FIREBASE.firestore
const STORAGE = FIREBASE.storage

const PRINT_QUEUE = "printQueue"

// DOCUMENT
function documentDataChannel(documentKey) {
    return eventChannel( emiter => {
        
        console.debug(`Channel did START with folder key ${documentKey}`)

        const unsubscribe = FIRESTORE.collection(PRINT_QUEUE).doc(documentKey).onSnapshot( doc => {
            emiter({ data: doc.data() || {} })
        })

        return () => {
            unsubscribe()
        }
    })
}

function* documentDataSaga() {

    const currentState = yield select()
    const channel = yield call(documentDataChannel, currentState.documentKey)

    try {
        while (true) {
            const { data } = yield take(channel)

            yield putResolve(ACTIONS.setRemoteFileInfo(data))
            yield putResolve(ACTIONS.setOperationStatus(C.OPERATION_IDLE))
        }
    } finally {
        if (yield cancelled()) {
            channel.close()
            console.log("Folder data channel did CLOSE")
        }
    }
}

function* documentDataWatcher() {

    while (yield take(ACTION_TYPES.START_DOCUMENT_DATA_LISTENER)) {

        console.debug("Folder data watcher did START")

        const task = yield fork(documentDataSaga)
        yield take(ACTION_TYPES.STOP_DOCUMENT_DATA_LISTENER)
        yield cancel(task)

        console.debug("Folder data watcher did STOP")
    }
}


// USER DATA
function userDataChannel(userUid) {
    return eventChannel( emiter => {
        
        console.debug(`User data channel with user ID ${userUid}`)

        const unsubscribe = FIRESTORE.collection("userData").doc(userUid).onSnapshot( doc => {
            const data = doc.data()
            const documentKey = data ? data.documentKey : null
            emiter({ documentKey: documentKey })
        })

        return () => {
            unsubscribe()
        }
    })
}

function* userDataSaga() {

    const currentState = yield select()
    const channel = yield call(userDataChannel, currentState.user.uid)

    try {
        while (true) {
            const { documentKey } = yield take(channel)
            console.debug("documentKey: ", documentKey)
            yield putResolve(ACTIONS.setDocumentKey(documentKey))
        }
    } finally {
        if (yield cancelled()) {
            channel.close()
            console.log("User data channel did CLOSE")
        }
    }
}

function* userDataWatcher() {

    while (yield take(ACTION_TYPES.START_USER_DATA_LISTENER)) {

        console.debug("User data watcher did START")

        const task = yield fork(userDataSaga)
        yield take(ACTION_TYPES.STOP_USER_DATA_LISTENER)
        yield cancel(task)

        console.debug("User data watcher did STOP")
    }
}

// FIREBASE/FIRESTORE AUTH LISTENER
function* authStateSaga() {

    const channel = new eventChannel(emiter => {
        
        const listener = FIREBASE.auth.onAuthStateChanged(function(user) {
            console.debug("*** Firebase 'AuthState' changed", user)
            emiter({ user: user })
        })

        return () => {
            listener.off()
        }
    })

    while (true) {
        const { user } = yield take(channel)
        yield put(ACTIONS.authStateChange(user))
    }
}

// FIRESTORE FILE UPLOAD
// https://medium.com/@amitaggrawal95/uploading-video-with-progress-bar-using-redux-saga-firebase-storage-123d6007dda1
function uploadFileChannel(file, documentKey) {

    return eventChannel(emittter => {
        const uploadTask = STORAGE.ref().child(documentKey).put(file)
        uploadTask.on("state_changed", snapshot => {

                // Progress
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                console.debug(`File upload progress: ${progress}%`)
                emittter({ progress })
            },
            error => {

                // Error
                console.error(error);
                emittter({ error });
            },
            () => {
                emittter({ END })
            })

            return () => {
                uploadTask.off("state_changed");
            }
    })
}


function* fileDrop(action) {

    const state = yield select()
    const { user } = state
    const file = action.payload

    console.debug("New file has dropped: ", file)

    // login check
    if (!user || !user.email || !user.emailVerified) {
        console.debug("No user, upload interrupted!")
        return
    }

    yield putResolve(ACTIONS.setFile(file))
}

// FIRESTORE FILE UPLOAD
function* uploadFile(action) {

    const state = yield select()
    const file = state.file

    console.debug(`Starting file upload: ${state.file.name} with folder key ${state.documentKey}`)

    yield putResolve(ACTIONS.setUploadProgress(0))
    yield putResolve(ACTIONS.setOperationStatus(C.OPERATION_FRONTEND_IS_WORKING))

    const channel = yield call(uploadFileChannel, file, state.documentKey)
    while (true) {
        const { progress = 0, error, END } = yield take(channel)
        
        if (error) {
            console.error('Channel error: ', error)
            channel.close()
            yield put(ACTIONS.setOperationStatus(C.OPERATION_IDLE))
            return
        }
        if (END) {
            console.debug('Upload completed!')
            yield put(ACTIONS.setOperationStatus(C.OPERATION_WAITING_FOR_BACKEND))
            return
        }
    
        yield put(ACTIONS.setUploadProgress(progress));
    }
}


// FIRESTORE FILE DELETE
function* deleteFile(action) {

    const { documentKey } = yield select()
    console.debug(`Folder key in use: ${documentKey}`)

    try {
        const ref = FIRESTORE.collection(PRINT_QUEUE).doc(documentKey)
        yield ref.update({
            deleted: true,
        })
        //yield put(ACTIONS.setOperationStatus(C.OPERATION_WAITING_FOR_BACKEND))
    } catch (err) {

        const flash = new Flash(Flash.ERROR, "Delete Document Error!", err.message, err)
        yield put(ACTIONS.flash(flash))

        console.error('Error during doing delete file saga: ', err)
    }
}

function* signInPopup(provider) {
    yield putResolve(ACTIONS.setSignInInProgressFlag(true))
    yield FIREBASE.auth.signInWithPopup(provider)
}

function* signInWithGoogle(action) {
    try {
        yield signInPopup(new FIREBASE.googleAuthProvider())
    } catch (err) {
        
        const flash = new Flash(Flash.ERROR, "Google Sign In Error!", err.message, err)
        yield putResolve(ACTIONS.flash(flash))

        yield put(ACTIONS.setSignInInProgressFlag(false))
    }
}

function* signInWithFacebook(action) {
    try {
        yield signInPopup(new FIREBASE.facebookAuthProvider())
    } catch (err) {
        
        const flash = new Flash(Flash.ERROR, "Facebook Sign In Error!", err.message, err)
        yield putResolve(ACTIONS.flash(flash))

        yield put(ACTIONS.setSignInInProgressFlag(false))
    }
}

function* signInWithApple(action) {

    const provider = new FIREBASE.oauthProvider('apple.com')
    provider.addScope('email')
    provider.addScope('name')

    try {
        yield signInPopup(provider)
    } catch (err) {
        
        const flash = new Flash(Flash.ERROR, "Apple Sign In Error!", err.message, err)
        yield putResolve(ACTIONS.flash(flash))

        yield put(ACTIONS.setSignInInProgressFlag(false))
    }
}

function* signIn(action) {
    try {
        yield putResolve(ACTIONS.setSignInInProgressFlag(true))
        yield FIREBASE.doSignInWithEmailAndPassword(action.payload.username, action.payload.password)      
        yield put(ACTIONS.signInSuccess())
    
    } catch (err) {
       
        const flash = new Flash(Flash.ERROR, "Sign In Error!", err.message, err)
        yield putResolve(ACTIONS.flash(flash))
        
        yield put(ACTIONS.setSignInInProgressFlag(false))

        console.error('Error during doing sign in saga: ', err)
    }
}

function* signOut(action) {
    try {
        yield FIREBASE.doSignOut()
    } catch (err) {

        const flash = new Flash(Flash.ERROR, "Sign Out Error!", err.message, err)
        yield putResolve(ACTIONS.flash(flash))

        console.error('Error during doing sign out saga: ', err)
    }
}

function* generateDocumentKey(action) {

    const state = yield select()
    
    if (state.user) {
        const userUid = state.user.uid
        const newFolderKey = uuidv4()
        
        console.debug(`New locally generated folder key is: ${newFolderKey}`)
    
        try {
            const ref = FIRESTORE.collection("userData").doc(userUid)
            yield ref.set({
                documentKey: newFolderKey,
                //modified //TODO: FieldValue
            }, {merge: true})
        } catch (err) {

            const flash = new Flash(Flash.ERROR, "Backend Error!", err.message, err)
            yield putResolve(ACTIONS.flash(flash))

            console.error('Error during doing generateing folder key saga: ', err)
        }
    } else {
        console.info("You are not logged in!")
    }
}

function* redirectHandler(action) {

    const to = action.payload

    if (to.startsWith("https://") || to.startsWith("http://")) {

        console.debug(`Set LOCATION to '${to}'. Application will finish running! State will be lost!`)
        window.location.href = to
        
        return //NOTE: This endpoint will never be reached! React app will finish running, state will be lost!

    } else {

        yield historyInstance.push(to)
        console.debug(`REDIRECT to '${to}' action processed. History instance:`, historyInstance)
    
    }
}

function* createUser(action) {
    const { email, password } = action.payload
    
    console.debug(`Create user with email ${email} and  password${password}`)

    try {
        yield FIREBASE.doCreateUserWithEmailAndPassword(email, password)
        yield take(ACTION_TYPES.AUTH_STATE_CHANGE)
        yield putResolve(ACTIONS.sendEmailVerification())
        yield put(ACTIONS.createUserSuccess())

        const flash = new Flash(Flash.SUCCESS, "Success", `User has been created with email ${email}`)
        yield putResolve(ACTIONS.flash(flash))

    } catch (err) {

        const flash = new Flash(Flash.ERROR, "Sign Up Error!", err.message, err)
        yield putResolve(ACTIONS.flash(flash))

        console.error('Error during doing createUser saga: ', err)
    }
}

function* sendEmailVerification() {
    
    try {
        const currentState = yield select()   
        yield currentState.user.sendEmailVerification()
        yield put(ACTIONS.sendEmailVerificationSuccess()) 
    } catch (err) {
        
        const flash = new Flash(Flash.ERROR, "Backend Error", err.message, err)
        yield putResolve(ACTIONS.flash(flash))

        console.error('Error during Send Email Verification saga: ', err)
    }
}

function* restartDocumentDataListener(action) {
    
    const currentState = yield select()
    
    if (currentState.documentKey) {
        yield putResolve(ACTIONS.stopDocumentDataListener())
        yield putResolve(ACTIONS.startDocumentDataListener())
    } else {
        console.debug("Folder key IS NULL, data listener did not start!")
    }
}

function* authStateChangeCoordinator() {
   
    const currentState = yield select()

    console.debug("Auth state chaged. User: ", currentState.user)
    
    if (currentState.user) {

        yield putResolve(ACTIONS.setSignInInProgressFlag(false))
        
        if (currentState.user.emailVerified) {
        
            yield putResolve(ACTIONS.startUserDataListener())
            yield putResolve(ACTIONS.redirect(ROUTES.HOME))
        
        } else {
            yield put(ACTIONS.redirect(ROUTES.NOT_VERIFIED))
        }

    } else {

        yield put(ACTIONS.stopUserDataListener())
        yield put(ACTIONS.stopDocumentDataListener())
        yield put(ACTIONS.redirect(ROUTES.HOME))

    }
}

function* reloadUser() {
    
    let currentState = yield select()
    yield currentState.user.reload()

    yield putResolve(ACTIONS.authStateChange(currentState.user)) //We have to call authStateChange here manually!
    
    if (currentState.user.emailVerified) {

        yield put(ACTIONS.redirect(ROUTES.HOME))
    
    } else {

        console.debug("Account hasn't been verified yet!")

    }
}

function* sendPasswordResetEmail(action) {

    const email = action.payload

    try {
        console.debug(`Send password reset email saga has been called with ${email}`)
        
        yield FIREBASE.auth.sendPasswordResetEmail(email)
        
        const flash = new Flash(Flash.SUCCESS, "Success", `Password reset link has been sent to ${email}`)
        yield put(ACTIONS.flash(flash))

    } catch (err) {
        
        const flash = new Flash(Flash.ERROR, "Backend Error!", err.message, err)
        yield putResolve(ACTIONS.flash(flash))

        console.error('Error during Send Password Reset Email saga: ', err)
    }
}

function* deleteUser(action) {
    
    try {
        const currentState = yield select()
        const providerId = currentState.user.providerData[0].providerId
        
        console.debug("deleteUser() provider ID: ", providerId)

        const cu = FIREBASE.auth.currentUser
        
        const aap = new FIREBASE.oauthProvider('apple.com')
        const gap = new FIREBASE.googleAuthProvider()
        const eap = new FIREBASE.emailAuthProvider()
        const fap = new FIREBASE.facebookAuthProvider()

        if (providerId === aap.providerId) {
            console.debug("Apple reauthenticate")
            yield cu.reauthenticateWithPopup(aap)
        }

        if (providerId === gap.providerId) {
            console.debug("Google reauthenticate")
            yield cu.reauthenticateWithPopup(gap)
        }

        if (providerId === fap.providerId) {
            console.debug("Facebook reauthenticate")
            yield cu.reauthenticateWithPopup(fap)
        }

        if (providerId === eap.providerId) {
            
            console.debug("Email reauthenticate")

            // We allow here to thraw literal
            // eslint-disable-next-line
            if (!action.password || action.password.length < 3) throw {
                message: "Password is invalid! Password must be more than 3 characters!"
            }

            yield cu.reauthenticateWithCredential(FIREBASE.emailAuthProvider.credential(
                currentState.user.email, 
                action.password
            ))

        }

        yield currentState.user.delete()
        console.debug(`User ${currentState.user.displayName} has been deleted.`)
    
    } catch (err) {

        const flash = new Flash(Flash.ERROR, "Backend Error!", err.message, err)
        yield putResolve(ACTIONS.flash(flash))
    
        console.error('Error during Delete User saga: ', err)
    }
}

// GENERAL WATCHERS
function* generalWatchers(action) {

    yield takeEvery(ACTION_TYPES.SIGN_IN, signIn)
    yield takeEvery(ACTION_TYPES.SIGN_IN_WITH_GOOGLE, signInWithGoogle)
    yield takeEvery(ACTION_TYPES.SIGN_IN_WITH_FACEBOOK, signInWithFacebook)
    yield takeEvery(ACTION_TYPES.SIGN_IN_WITH_APPLE, signInWithApple)

    yield takeEvery(ACTION_TYPES.SIGN_OUT, signOut)
    yield takeEvery(ACTION_TYPES.CREATE_USER, createUser)
    yield takeEvery(ACTION_TYPES.RELOAD_USER, reloadUser)

    yield takeEvery(ACTION_TYPES.FILE_DROP, fileDrop)
    yield takeEvery(ACTION_TYPES.SET_FILE, uploadFile)
    yield takeEvery(ACTION_TYPES.DELETE_FILE, deleteFile)
    
    yield takeEvery(ACTION_TYPES.GENERATE_DOCUMENT_KEY, generateDocumentKey)
    yield takeEvery(ACTION_TYPES.SET_DOCUMENT_KEY, restartDocumentDataListener)
    yield takeEvery(ACTION_TYPES.AUTH_STATE_CHANGE, authStateChangeCoordinator)

    yield takeEvery(ACTION_TYPES.REDIRECT, redirectHandler)

    yield takeEvery(ACTION_TYPES.SEND_EMAIL_VERIFICATION, sendEmailVerification)
    yield takeEvery(ACTION_TYPES.SEND_PASSWORD_RESET_EMAIL, sendPasswordResetEmail)

    yield takeEvery(ACTION_TYPES.DELETE_USER, deleteUser)
}


// ROOT EXPORT
export default function* sagas() {
   
    yield spawn(authStateSaga)
 
    yield spawn(userDataWatcher)
    yield spawn(documentDataWatcher)

    yield spawn(generalWatchers)
}