import React, { useState } from 'react'

import PPButton from '../App/PPButton'
import PPInput from '../App/PPInput'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: "3rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: ".75rem",
        "& button": {
            marginTop: "1rem"
        },
    }
}))

const Inputs = (props) => {

    const classes = useStyles()
    const { autocompleteOff, buttonLabel } = props

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const handleEmailChange = (ev) => {
        setEmail(ev.target.value)
    }

    const handlePasswordChange = (ev) => {
        setPassword(ev.target.value)
    }

    const handleButtonClick = (ev) => {
        console.debug(`${buttonLabel} button clicked: `, ev)
        props.handleClick(email, password)
    }

    return (
            <form autoComplete="off" className={classes.root}>
                <PPInput
                    fullWidth
                    onChange={handleEmailChange}
                    placeholder="Email address"
                    type="text"
                    id="input-email"
                    autocompleteOff={autocompleteOff} />
                <PPInput
                    fullWidth
                    onChange={handlePasswordChange}
                    placeholder="Password"
                    type="password"
                    id="input-password"
                    autocompleteOff={autocompleteOff} />
                <PPButton
                    fullWidth
                    onClick={handleButtonClick}>
                    {buttonLabel}
                </PPButton>
            </form>
    )
}

export default Inputs