import React from 'react'
import { connect } from "react-redux"

import * as ACTIONS from "../../redux/actions"
import * as ROUTES from "../../misc/routes"

import { 
    makeStyles,
    Link,
} from '@material-ui/core'

import RemoteFileInfo from './RemoteFileInfo'
import StyledDropzone from './StyledDropzone'
import Flash from "../Flash/Flash"

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    box: {
        flex: 1,
        width: "100%",
        height: "100%",
        borderRadius: "0.5rem",
        background: theme.printPrincess.colors.inputBackground,
        boxShadow: theme.printPrincess.shadows.input,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "red",
    },
}))

const DragAndDropArea = (props) => {

    const classes = useStyles()
    const { state } = props
    const isSignedIn = state.user && state.user.email && state.user.emailVerified

    const onDrop = (file) => {
        props.fileDrop(file)
    }

    const onError = (errors) => {
        const errorMessages = errors.map( e => e.message)
        const flash = new Flash(Flash.ERROR, "File Error!", ["The file has a following error(s):", ...errorMessages], errors)
        props.flash(flash)
    }

    const handleSignInClick = () => {
        props.redirect(ROUTES.SIGN_IN)
    }

    if (!isSignedIn) return (
        <div className={classes.box}>
            <span>Please <Link onClick={handleSignInClick}>sign in</Link> to use the service.</span>
        </div>
    )

    return (
        <div className={classes.root}>
            <StyledDropzone
                isSignedIn={isSignedIn}
                onError={onError}
                onDrop={onDrop} />
            {isSignedIn ? <RemoteFileInfo /> : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        fileDrop: (file) => dispatch(ACTIONS.fileDrop(file)),
        redirect: (to) => dispatch(ACTIONS.redirect(to)),
        flash: (payload) => dispatch(ACTIONS.flash(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DragAndDropArea)

