import React from 'react'
import { connect } from "react-redux"
import * as ACTIONS from "../../redux/actions"
import * as ROUTES from "../../misc/routes"

import theme from '../../theme/default.json'

import {
    makeStyles,
    Link
} from '@material-ui/core/'

import AppIconWithText from './AppIconWithText'
import MailTo from './MailTo'

import EmailCircleIcon from '../Icons/EmailCircleIcon'

const useStyles = makeStyles((theme) => ({
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    color: theme.printPrincess.colors.numbered[1],
    display: "flex",
    justifyContent: "column",
    alignItems: "center",
    flexDirection: "column",
    padding: "1rem",
  },
  copyright: {
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  disclaimer: {
    fontWeight: "500",
    fontSize: theme.printPrincess.fonts.normal,
    "& a": {
      color: theme.printPrincess.colors.white,
      textDecoration: "underline"
    }
  },
  company: {
    fontSize: "85%",
    color: theme.printPrincess.colors.numbered[2],
    "& > a": {
      color: theme.printPrincess.colors.numbered[2]
    }
  },
  social: {
    display: "flex",
    gap: "1rem",
    "& svg": {
      width: "2rem",
      height: "2rem",
      fill: theme.printPrincess.colors.numbered[1],
    }
  },
  [theme.breakpoints.up('md')]: {
    root: {
      flexDirection: "row",
      alignItems: "flex-start",
    },
    social: {
      order: 1,
    }
  }
}))

const Footer = (props) => {

    const classes = useStyles(theme)

    const handleDisclaimerClick = ev => {
      props.redirect(ROUTES.DISCLAIMER)
    }

    return (
      <footer className={classes.root}>
        <div className={classes.logo}>
          <AppIconWithText numberedColor={1} />
        </div>
        <div className={classes.social}>
          <MailTo><EmailCircleIcon /></MailTo>
        </div>
        <div className={classes.copyright}>
          <span className={classes.disclaimer}><Link onClick={handleDisclaimerClick}>Disclaimer</Link></span>
          <span className={classes.company}>&copy;&nbsp;<a href="https://starcat.dev">StarCat.dev Ltd.</a></span>
        </div>
		  </footer>
    )
}

const mapStateToProps = (state) => ({
  state: state
})

const mapDispatchToProps = dispatch => {
  return {
      redirect: (to) => dispatch(ACTIONS.redirect(to)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)