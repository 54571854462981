// OPERATION STATUS
export const OPERATION_IDLE = "OPERATION_IDLE"
export const OPERATION_FRONTEND_IS_WORKING = "OPERATION_FRONTEND_IS_WORKING"
export const OPERATION_WAITING_FOR_BACKEND = "OPERATION_WAITING_FOR_BACKEND"

// DRAG STATUS
export const DRAG_STATUS_NO_DRAG = "DRAG_STATUS_NO_DRAG"
export const DRAG_STATUS_DRAG_IN_PROGRESS = " DRAG_STATUS_DRAG_IN_PROGRESS"

// YES/NO
export const YES = "YES"
export const NO = "NO"

// MIME TYPES
export const SUPPORTED_MIME_TYPES = ['image/png','image/gif','image/jpeg','image/bmp','application/pdf']
export const isMimeTypeSupported = (mimeType) => {
    for (let i = 0; i < SUPPORTED_MIME_TYPES.length; i++) {
        if (SUPPORTED_MIME_TYPES[i].includes(mimeType)) { return true }
    }
    return false
}

export const cls = (...c) => {
    let r = "" 
    c.forEach( i => r += ` ${i}`)
    return r
}

export class ClassBuilder {
    
    constructor(...classes) {
        this.classes = []
        this.classes.push(...classes)
    }

    add(...classes) {
        this.classes.push(...classes)
    }

    clear() {
        this.classes = []
    }

    asString() {
        return cls(...this.classes)
    }
}
