import React from 'react'

import { connect } from "react-redux"

import Panel from '../Panel/Panel'
import Page from '../App/Page'
import DragAndDropArea from './DragAndDropArea'
import OperationStatus from './OperationStatus'

const Upload = (props) => {

    return (
        <Page showDownloadBadges>
            <Panel title="File upload" showPaper>
                <DragAndDropArea />
                <OperationStatus />
            </Panel>
        </Page>
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
        //
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Upload)