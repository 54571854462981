import React, { useState } from 'react'
import { connect } from "react-redux"

import AppIconWithText from './AppIconWithText'
import Username from './Username'

import { 
  makeStyles,
  MenuItem,
  Popover,
} from '@material-ui/core/'

import * as ACTIONS from "../../redux/actions"
import * as ROUTES from '../../misc/routes'

const useStyles = makeStyles((theme) => ({
  root: {
      position: "absolute",
      zIndex: "100",
      top: "0",
      left: "0",
      width: "100%",
      padding: "1rem",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "space-between",
    },
    button: {
      fontSize: "1rem",
      background: "#52B8DC",
      boxShadow: "-0.25rem -0.25rem 0.5rem #6FD2E9, 0.25rem 0.25rem 0.5rem #489CBA",
      border: "solid 1px rgba(255, 255, 255, .2)",
      borderRadius: ".5rem",
      color: theme.printPrincess.colors.white,
      paddingLeft: "2rem",
      paddingRight: "2rem",
      "&:hover": {
        boxShadow: "none",
        border: "none",
      },
      "&:active": {
        boxShadow: "0.25rem 0.25rem 0.5rem #489CBA",
      },
    },
    popover: {
      "& li": {
        color: theme.printPrincess.colors.numbered[2],
        padding: ".25rem 1rem .25rem 1rem",
        margin: 0,
      }
    },
    [theme.breakpoints.up('md')]: {
      root: {
        position: "sticky"
      }
    }
}))

const NavBar = (props) => {

    const classes = useStyles()
    const { state } = props 
    const { user, signInInProgress } = state 
    const isLoggedIn = user && user.emailVerified
    const label = isLoggedIn ? <Username /> : "Sign in"
    const [anchorEl, setAnchorEl] = useState(null)
   
    const handleIconClick = () => {
      props.redirect(ROUTES.HOME)
    }
  
    const handleClose = () => {
      setAnchorEl(null)
    }
  
    const handleProfile = () => {
      handleClose()
      props.redirect(ROUTES.PROFILE)
    }
  
    const handleSignOut = () => {
      handleClose()
      props.signOut()
    }
  
    const handleSignIn = (ev) => {
      props.redirect(ROUTES.SIGN_IN)
    }

    const handleButtonClick = (ev) => {
      if (!isLoggedIn) { handleSignIn(ev) } else { setAnchorEl(ev.currentTarget) }
    }

    if (signInInProgress) return null //<CircularProgress />

    return (
      <>

        <Popover
          keepMounted
          id="profile-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={classes.popover}
          >
          <MenuItem onClick={handleProfile}>Profile</MenuItem>
          <MenuItem onClick={handleSignOut}>Logout</MenuItem>
        </Popover>

        <nav className={classes.root}>
          <AppIconWithText onClick={handleIconClick} compact/>
          <button className={classes.button} onClick={handleButtonClick}>{label}</button>
        </nav>

      </>
    )
}

const mapStateToProps = (state) => ({
    state: state
})

const mapDispatchToProps = dispatch => {
    return {
      signOut: () => dispatch(ACTIONS.signOut()),
      redirect: (to) => dispatch(ACTIONS.redirect(to)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)